import { useAuth0 } from '@auth0/auth0-react';
import { SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { DialogActions } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TypeErrorLogin } from '../../interfaces/user';
import { VamDialog } from '../shared';

interface HandleErrorLoginDialogProps {
  errorType: TypeErrorLogin;
  open: boolean;
}

export function HandleErrorLoginDialog({ errorType, open }: HandleErrorLoginDialogProps) {
  const { t } = useTranslation('common', { keyPrefix: 'errorLogin' });
  const { logout } = useAuth0();

  return (
    <VamDialog
      title={t(`${errorType}.title`)}
      isOpen={open}
      fullWidth
      maxWidth="sm"
      withCloseButton={false}
      dialogActions={
        <DialogActions className="!tw-justify-center ">
          <SimpleButton
            data-testid="apply-logout-error-login-button"
            variant="cta"
            id="apply-logout-error-login-button"
            size="large"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin + '/' } })}
          >
            {t('logout')}
          </SimpleButton>
        </DialogActions>
      }
    >
      <Typo variant="body1" className="tw-flex tw-items-center tw-justify-center tw-text-center">
        {t(`${errorType}.subtitle`)}
      </Typo>
    </VamDialog>
  );
}
