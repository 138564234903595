import { zodResolver } from '@hookform/resolvers/zod';
import { Check } from '@mui/icons-material';
import { Grid2, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TypeOf } from 'zod';

import { useAppSelector } from '../../hooks';
import { VegCase } from '../../interfaces/case';
import { updateCase } from '../../services/api/case-api';
import { selectUser } from '../../services/store/userSlice';
import { CaseStatus } from '../../utils/constants/set-model';
import { formatNameAndId, redirectTo } from '../../utils/functions/helpers';
import { ContainerOutlet } from '../layout/ContainerOutlet/ContainerOutlet';
import { CtaLoadingButton, VamPageTitle } from '../shared';
import { updateFormSchema } from './CaseForm';
import { CaseInformations } from './CaseInformations';
import { GeneralInformations } from './GeneralInformations';

type FormSchemaType = TypeOf<typeof updateFormSchema>;

export default function UpdateCase() {
  const { t } = useTranslation('gauge_case', { keyPrefix: 'updateCase' });
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useLoaderData() as { data: VegCase };
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { firstName, lastName } = useAppSelector(selectUser);
  const caseId = params['caseId'];
  const defaultValues: FormSchemaType = {
    price: data.price,
    case_status: data.case_status,
    designation: data.designation,
    width: data.width,
    length: data.length,
    height: data.height,
    weight: data.weight,
  };
  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(updateFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit } = methods;

  /* istanbul ignore next */
  const saveCase: SubmitHandler<FormSchemaType> = (data) => {
    const dataApi: VegCase = {
      designation: data.designation,
      width: data.width,
      height: data.height,
      length: data.length,
      weight: data.weight,
      price: data.price,
      case_status: data.case_status as CaseStatus,
      modified_by: `${firstName} ${lastName}`,
    };
    updateCase(caseId, dataApi)
      .then(() => {
        redirectTo('../cases-listing', navigate, {
          state: { from: location },
        });
        enqueueSnackbar(t('saveCaseSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(t('saveCaseError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('tag')}
              title={t('title')}
              detailsLabelBox={[
                {
                  label: formatNameAndId(caseId, data.designation),
                },
              ]}
              breadcrumbRoutes={[
                {
                  breadcrumb: t('breadcrumb.caseListing'),
                  path: '../case-listing',
                },
                {
                  breadcrumb: t('breadcrumb.addNewCase'),
                  path: '#',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <CtaLoadingButton
              buttonText={t('saveButton')}
              type="submit"
              variant="cta"
              data-testid="submit-update-case-btn"
              startIcon={<Check />}
              onClick={handleSubmit(saveCase)}
              disabled={
                methods.formState.isLoading ||
                !methods.formState.isValid ||
                !!Object.keys(methods.formState.errors).length
              }
            />
          </Stack>
        </Stack>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 3 }}>
            <GeneralInformations data={data} />
          </Grid2>
          <Grid2 size={{ xs: 9 }}>
            <CaseInformations />
          </Grid2>
        </Grid2>
      </FormProvider>
    </ContainerOutlet>
  );
}
