import { CircleStatus, SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  SetModelDetails,
  SetModelsManagementFilterParams,
  SetModelsManagementGrid,
  SetModelsManagementParams,
} from '../../../interfaces/set-model';
import { exportCsvSetModels, getSetModelsManagement } from '../../../services/api/set-model-api';
import {
  DEFAULT_PAGINATION_MODEL,
  OD_MAPPING,
  VegWebsiteStatus,
  WEBSITE_STATUS_GRID_MAPPING,
} from '../../../utils';
import {
  formatDate,
  mapToProductFilterFromArrays,
  removeFalsyPropertyFromArrays,
} from '../../../utils/functions';
import { redirectTo } from '../../../utils/functions';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { columnRenderHeader } from '../../data-grid/utils/functions';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import {
  BoxContainer,
  CountResultFilter,
  VamFilter,
  VamMoreOptions,
  VamPageTitle,
} from '../../shared';
import { SetModelsManagementFilter } from './SetModelsManagementFilter';
import { FormSchemaType, defaultValues, formSchema } from './SetModelsManagementFilterForm';

const hideColumns = {
  // Hide columns status and traderName, the other columns will remain visible
  modification_date: false,
  modified_by: false,
  creation_date: false,
  creation_by: false,
};

export function SetModelsManagement() {
  const { t } = useTranslation('gauge', { keyPrefix: 'setModelsManagement' });
  const { t: tGauge } = useTranslation('gauge');
  const { t: tCommon } = useTranslation('common');
  const [setModels, setSetModels] = React.useState<SetModelsManagementGrid[]>([]);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [count, setCountSize] = React.useState<number>(0);
  const [loadingGrid, setLoadingGrid] = React.useState<boolean>(false);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [datafilter, setDataFilter] = React.useState<SetModelsManagementFilterParams>({});

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit } = methods;
  const navigate = useNavigate();
  const location = useLocation();

  const optionsMenu = [
    {
      name: 'seeDetails',
      label: tGauge('myGauges.actions.seeDetails'),
      handleItemClick: (_, __, row) => {
        redirectToUpdateSetModel(row);
      },
    },
  ];

  /* istanbul ignore next */
  const cols: GridColDef[] = [
    {
      field: 'id',
      headerName: 'SET MODEL#',
      flex: 1,
    },
    {
      field: 'connection',
      headerName: 'CONNECTION',
      flex: 1.25,
    },
    {
      field: 'od_inch',
      headerName: 'DIAMETER',
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<any, number>) =>
        OD_MAPPING?.[params.value] || params.value,
    },
    {
      field: 'min_weight_max_weight',
      headerName: 'WEIGHT',
      flex: 1.5,
    },
    {
      field: 'end',
      headerName: 'END',
      flex: 1,
    },
    {
      field: 'application',
      headerName: 'APPLICATION',
      flex: 1.5,
    },
    {
      field: 'option',
      headerName: 'OPTION',
      flex: 1,
    },
    {
      field: 'website_status',
      renderHeader: () => columnRenderHeader('WEB', 'STATUS'),
      renderCell: (params: GridRenderCellParams<any, VegWebsiteStatus>) => (
        <CircleStatus variant={params.value.toLowerCase() as any} />
      ),
      flex: 1,
    },
    {
      field: 'modification_date',
      renderHeader: () => columnRenderHeader('MODIFICATION', 'DATE'),
      flex: 1,
    },
    {
      field: 'modified_by',
      renderHeader: () => columnRenderHeader('MODIFIED', 'BY'),
      flex: 1,
    },
    {
      field: 'creation_date',
      renderHeader: () => columnRenderHeader('CREATION', 'DATE'),
      flex: 1,
    },
    {
      field: 'creation_by',
      renderHeader: () => columnRenderHeader('CREATED', 'BY'),
      flex: 1,
    },
    {
      field: 'action',
      align: 'center',
      flex: 0.5,
      renderHeader: () => <></>,
      renderCell: ({ row }) => {
        return <VamMoreOptions options={optionsMenu} row={row} />;
      },
      sortable: false,
      hideable: false,
    },
  ];

  useEffect(() => {
    const fetchData = async (params: SetModelsManagementParams) => {
      fetchGetSetModels(params);
    };
    const skipPage = paginationModel.page * paginationModel.pageSize;

    fetchData({
      data_filter: datafilter,
      pagination: { skip: skipPage, limit: paginationModel.pageSize },
      sort: sortModel,
    });
  }, [paginationModel.page, sortModel]);

  const redirectToUpdateSetModel = async (row: SetModelDetails) => {
    const { id } = row;
    redirectTo(`${id}`, navigate, {
      state: { from: location },
    });
  };

  const fetchGetSetModels = (params: SetModelsManagementParams) => {
    setLoadingGrid(true);
    getSetModelsManagement(params)
      .then(({ data }) => {
        const dataGrid = data?.set_models?.map((row, idx) => {
          return {
            ...row,
            creation_date: formatDate(row.creation_date, false),
            modification_date: formatDate(row.modification_date, false),
            grid_status: WEBSITE_STATUS_GRID_MAPPING[row.website_status],
          } as SetModelsManagementGrid;
        });
        setCountSize(data.count);
        setSetModels(dataGrid);
      })
      .catch((error) => {
        console.error('🚀 ~ file: SetModelsManagement.tsx ~ fetch ~ error', error);
      })
      .finally(() => {
        setLoadingGrid(false);
      });
  };

  const applyFilter: SubmitHandler<FormSchemaType> = (data) => {
    const filter = removeFalsyPropertyFromArrays(
      mapToProductFilterFromArrays(data as Record<string, any[]>, ['website_status']) as Record<
        string,
        any[]
      >
    );
    setDataFilter(filter);
    const skipPage = paginationModel.page * paginationModel.pageSize;
    fetchGetSetModels({
      data_filter: filter,
      pagination: { skip: skipPage, limit: paginationModel.pageSize },
      sort: sortModel,
    });
  };

  const downloadCSV = async () => {
    exportCsvSetModels({
      data_filter: datafilter,
      pagination: { skip: 0, limit: count },
      sort: sortModel,
    })
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const filename = 'export_set_models_results.csv';
        saveAs(blob, filename);
        enqueueSnackbar(tCommon('notification.successExport'), {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <Stack direction="row" justifyContent="space-between">
        <VamPageTitle
          title={t('title')}
          subTitle={tGauge('tag')}
          breadcrumbRoutes={[
            {
              breadcrumb: 'Set Models Management',
              path: '#',
            },
          ]}
        />
      </Stack>
      <FormProvider {...methods}>
        <VamFilter
          title={t('titleFilter')}
          subTitle={t('subTitleFilter')}
          handleApplyFilter={handleSubmit(applyFilter)}
          disableApplyBtn={loadingGrid}
        >
          <SetModelsManagementFilter />
        </VamFilter>
      </FormProvider>
      <BoxContainer>
        <Stack
          className="tw-pb-8"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <CountResultFilter className="tw--ml-4" count={count} />
          <Stack direction="row" spacing={2}>
            <SimpleButton
              variant="secondary-dark"
              data-testid="btn-csv-download-set-models-management"
              onClick={() => downloadCSV()}
            >
              {tCommon('downloadResults')}
            </SimpleButton>
            <SimpleButton
              variant="text"
              data-testid="btn-add-new-set"
              onClick={() => redirectTo('../create', navigate)}
            >
              {t('addNewSet')}
            </SimpleButton>
          </Stack>
        </Stack>
        <VamDataGrid
          sx={{
            '.MuiBox-root': {
              height: 'auto',
            },
          }}
          data-testid="grid-set-models-management"
          initialStateColumns={{
            columnVisibilityModel: hideColumns,
          }}
          columns={cols}
          rows={setModels}
          height={500}
          disableColumnFilter
          disableRowSelectionOnClick
          showRowLeftThickBorder
          paginationMode="server"
          loading={loadingGrid}
          pagination
          rowCount={count}
          paginationModel={paginationModel}
          onPaginationModelChange={/* istanbul ignore next */ setPaginationModel}
          onRowDoubleClick={({ row }: GridRowParams) => {
            redirectToUpdateSetModel(row);
          }}
          sortingMode="server"
          onSortModelChange={
            /* istanbul ignore next */ (sortModel: GridSortModel) => {
              setPaginationModel({ ...paginationModel, page: 0 });
              setSortModel(sortModel);
            }
          }
          sortModel={sortModel}
        />
      </BoxContainer>
    </ContainerOutlet>
  );
}
