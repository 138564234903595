import { TypeOf, array, number, object, string } from 'zod';

import { REQUIRED_FIELD } from '../../../../utils';
import { makeReturnSetFormSchema } from '../../make-return/MakeReturnForm';

export const confirmReturnFormSchema = object({
  has_return_delivery_cost: string().optional(),
  return_delivery_cost: number().optional().nullable(),
  sets: array(makeReturnSetFormSchema),
  comments: string().optional().nullable(),
  currency: string().min(1, REQUIRED_FIELD),
  awb_number_dispatch: string().optional().nullable(),
  awb_number_return: string().optional().nullable(),
  dispatch_shipping_id: number().optional().nullable(),
});

export const defaultValues: FormSchemaType = {
  has_return_delivery_cost: 'false',
  return_delivery_cost: null,
  sets: [],
  comments: '',
  awb_number_dispatch: '',
  awb_number_return: '',
  dispatch_shipping_id: null,
};

export type FormSchemaType = TypeOf<typeof confirmReturnFormSchema>;
