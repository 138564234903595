import { boolean, date, number, object, string } from 'zod';

import { SetGauge, SetGaugeModelUI } from '../interfaces/set-creation';
import { REQUIRED_DATE_FIELD, REQUIRED_FIELD } from '../utils';

export function mappingSetContentApi(data: SetGaugeModelUI[], created_by: string): SetGauge[] {
  return data.map((item) => {
    const res = {
      id: item.id,
      serial_number: item.serial_number,
      is_present: item.is_present,
      creation_date: new Date(),
      next_calibration_date: item.next_calibration_date,
      created_by,
      gauge_model_id: item.id,
      is_damaged: item.is_damaged,
    };

    return res as SetGauge;
  });
}

export const clickAndCollectSchema = object({
  start_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  end_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  rental_type: string().min(1, { message: REQUIRED_FIELD }),
  connection: string().min(1, { message: REQUIRED_FIELD }),
  od_inch: number(),
  weight_thickness: string().min(1, { message: REQUIRED_FIELD }),
  end: string().min(1, { message: REQUIRED_FIELD }),
  application: string().min(1, { message: REQUIRED_FIELD }),
  option: string().min(1, { message: REQUIRED_FIELD }),
  product_drawing: string().min(1, { message: REQUIRED_FIELD }),
  end_user: string().min(1, { message: REQUIRED_FIELD }),
  purchase_order: string().min(1, { message: REQUIRED_FIELD }),
  deliveryAddress: number().optional().nullable(),
  comments: string().optional(),
  pickupSite: number(),
  pickupContactName: string().min(1, { message: REQUIRED_FIELD }),
  bident: boolean().optional(),
  has_stock: boolean(),
  // if mode is c&c, we must to put pickupSite in gauge_location_id.
  gauge_location_id: number().optional().nullable(),
});

export const deliverySchema = object({
  start_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  end_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  rental_type: string().min(1, { message: REQUIRED_FIELD }),
  connection: string().min(1, { message: REQUIRED_FIELD }),
  od_inch: number(),
  weight_thickness: string().min(1, { message: REQUIRED_FIELD }),
  end: string().min(1, { message: REQUIRED_FIELD }),
  application: string().min(1, { message: REQUIRED_FIELD }),
  option: string().min(1, { message: REQUIRED_FIELD }),
  product_drawing: string().min(1, { message: REQUIRED_FIELD }),
  end_user: string().min(1, { message: REQUIRED_FIELD }),
  purchase_order: string().min(1, { message: REQUIRED_FIELD }),
  deliveryAddress: number({ required_error: REQUIRED_FIELD }),
  comments: string().optional(),
  pickupSite: number().optional(),
  pickupContactName: string().optional(),
  bident: boolean().optional(),
  has_stock: boolean(),
  // if mode is c&c, we must to put pickupSite in gauge_location_id.
  gauge_location_id: number().optional().nullable(),
});
