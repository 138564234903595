import { Grid2 } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useIsFirstMount } from '../../../hooks';
import { useSelectOrFetchPuAddress } from '../../../hooks/useSelectOrFetchPuAddress';
import { Licensee } from '../../../interfaces/context';
import { DistinctConnectionLabelValue } from '../../../interfaces/distinct-product-label-value';
import { getLicensees } from '../../../services/api/context-api';
import { getDistinctBookingFilters } from '../../../services/api/veg-api';
import { globalDistinctConnection } from '../../../services/functions/globalDistinctConnection';
import { selectUser } from '../../../services/store/userSlice';
import { $primaryWhite } from '../../../styles/colors';
import {
  BookingStatus,
  TextFieldVariant,
  businessNumberListRegex,
  setIdListRegex,
} from '../../../utils';
import {
  mapToProductFilter,
  mapToProductFilterFromArrays,
  removeFalsyProperty,
  removeFalsyPropertyFromArrays,
  toLabelValues,
} from '../../../utils/functions';
import { FormDatePicker, FormTextField } from '../../shared';
import { FormAutocomplete } from '../../shared/form-components/FormAutocomplete';
import { FormMultiSelect } from '../../shared/form-components/FormMultiSelect';

interface GlobalSearchFilterProps {
  licensees: Licensee[];
  setLicensees: Function;
}

export function GlobalSearchFilter({ licensees, setLicensees }: GlobalSearchFilterProps) {
  const { t } = useTranslation('gauge');
  const user = useAppSelector(selectUser);
  const firstMount = useIsFirstMount();
  const { puAddressesLabelValue } = useSelectOrFetchPuAddress();
  const { control, setValue, getValues } = useFormContext();
  const { replace } = useFieldArray({
    control: control,
    name: 'gauge_location_id',
  });
  const [distinctBookingConnectionOptions, setDistinctBookingConnectionOptions] = React.useState({
    connection: [],
    od_inch: [],
    end: [],
    weight_thickness: [],
    application: [],
    option: [],
    status: [],
    licensee_number: [],
    gauge_location_id: [],
    period_end: null,
    period_start: null,
  } as DistinctConnectionLabelValue);

  const statusOptions = toLabelValues(
    BookingStatus,
    'manageBookingDetail.changeStatusDialog.status.'
  );

  React.useEffect(() => {
    if (firstMount && user.depotId) {
      replace([user.depotId]);
    }
    const fetchData = async () => {
      try {
        await handleChangeDistinctConnection();
      } catch (error) {
        /* istanbul ignore next */
        console.error('🚀 ~ file: GlobalSearchFilter.tsx ~ fetchData ~ error', error);
      }
    };
    fetchData();
  }, [control._formValues]);

  const handleChangeDistinctConnection = React.useCallback(async () => {
    const { _formValues } = control;
    const licenseeNumber = getValues('licensee_number');
    let filter = {
      ...removeFalsyPropertyFromArrays(
        mapToProductFilterFromArrays(_formValues, [
          'status',
          'weight_thickness',
          'gauge_location_id',
        ]) as Record<string, any[]>
      ),
    };
    filter = {
      ...filter,
      ...removeFalsyProperty(
        mapToProductFilter(
          { period_start: _formValues.period_start, period_end: _formValues.period_end },
          ['period_start', 'period_end']
        )
      ),
      licensee_number: licenseeNumber ? [licenseeNumber] : [],
    };

    const connection = (await globalDistinctConnection(getDistinctBookingFilters, filter))
      ?.connection;
    setDistinctBookingConnectionOptions(connection);
  }, []);

  const handleOnInputChange = async (
    _event: React.SyntheticEvent<Element, Event>,
    licenseeNumber: string
  ) => {
    if (licenseeNumber?.length) {
      const { data } = await getLicensees(licenseeNumber);
      setLicensees(data);
    }
  };

  const handleOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    licenseeNumberSelected: number
  ) => {
    setValue('licensee_number', licenseeNumberSelected);
    handleChangeDistinctConnection();
  };

  const removeLicenseeSelected = () => {
    setLicensees([]);
    setValue('licensee_number', null);
    handleChangeDistinctConnection();
  };

  const handleBlur = (event) => {
    if (!event?.target?.value?.length) {
      removeLicenseeSelected();
    }
  };

  return (
    <Grid2
      sx={{
        '&.MuiGrid-root': {
          backgroundColor: 'inherit',
        },
        '.MuiTextField-root .MuiOutlinedInput-root': {
          height: 39,
          backgroundColor: $primaryWhite,
        },
      }}
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid2 size={{ xs: 2 }}>
        <FormAutocomplete
          id="licensee-number"
          name="licensee_number"
          getOptionLabel={(option) => `${option}`} // To avoid warnings in console
          options={licensees.map((l) => l.licensee_number)}
          onInputChange={handleOnInputChange}
          onChange={handleOnChange}
          disableClearable={true}
          blurOnSelect
          noOptionsText={t('globalSearchBooking.notFound')}
          data-testid="search-licensee-autocomplete"
          type="number"
          variant={TextFieldVariant.Outlined}
          withFixedLabel
          labelValue="Licensee Number"
          onBlur={handleBlur}
        />
      </Grid2>
      <Grid2 size={{ xs: 2 }}>
        <FormDatePicker
          id="period-start-select"
          inputLabel="Period start"
          variant="outlined"
          name="period_start"
          dataTestId="period_start-test-id"
          onClose={handleChangeDistinctConnection}
        />
      </Grid2>
      <Grid2 size={{ xs: 2 }}>
        <FormDatePicker
          id="period-end-select"
          inputLabel="Period end"
          variant="outlined"
          name="period_end"
          dataTestId="period_end-test-id"
          onClose={handleChangeDistinctConnection}
        />
      </Grid2>
      <Grid2 size={{ xs: 2 }}>
        <FormMultiSelect
          id="connection-select"
          name="connection"
          label={`${t('details.connection')}`}
          options={distinctBookingConnectionOptions?.connection}
          onClose={handleChangeDistinctConnection}
        />
      </Grid2>

      <Grid2 size={{ xs: 2 }}>
        <FormMultiSelect
          id="od-select"
          name="od_inch"
          label={`${t('details.od')}`}
          options={distinctBookingConnectionOptions?.od_inch}
          onClose={handleChangeDistinctConnection}
        />
      </Grid2>

      <Grid2 size={{ xs: 2 }}>
        <FormMultiSelect
          id="weight-thickness-select"
          name="weight_thickness"
          label={`${t('details.weightTickness')}`}
          options={distinctBookingConnectionOptions?.weight_thickness}
          onClose={handleChangeDistinctConnection}
        />
      </Grid2>

      <Grid2 size={{ xs: 2 }}>
        <FormMultiSelect
          id="end-select"
          name="end"
          label={t('details.end')}
          options={distinctBookingConnectionOptions?.end}
          onClose={handleChangeDistinctConnection}
        />
      </Grid2>

      <Grid2 size={{ xs: 2 }}>
        <FormMultiSelect
          id="application-select"
          name="application"
          label={t('details.application')}
          options={distinctBookingConnectionOptions?.application}
          onClose={handleChangeDistinctConnection}
        />
      </Grid2>

      <Grid2 size={{ xs: 2 }}>
        <FormMultiSelect
          id="status-select"
          name="status"
          label={t('globalSearchBooking.status')}
          options={statusOptions}
          nsTranslate="gauge"
        />
      </Grid2>

      <Grid2 size={{ xs: 2 }}>
        <FormMultiSelect
          id="depot-select"
          name="gauge_location_id"
          label={t('details.depot')}
          options={puAddressesLabelValue}
          defaultSelectedOptions={[user.depotId]}
        />
      </Grid2>

      <Grid2 size={{ xs: 2 }} className="-tw-mt-[3px]">
        <FormTextField
          id="business-number-text-fields"
          withFixedLabel
          inputProps={{
            'data-testid': 'list-business-number-input',
          }}
          labelValue={t('details.businessNumber')}
          name="business_number"
          fullWidth
          patternValue={businessNumberListRegex}
          patternMessage="Error syntax"
          placeholder={t('details.businessNumberPlaceholder')}
        />
      </Grid2>
      <Grid2 size={{ xs: 2 }}>
        <FormTextField
          id="setids-list-text-fields"
          withFixedLabel
          labelValue={t('details.setIds')}
          name="set_id"
          fullWidth
          patternValue={setIdListRegex}
          patternMessage="Error syntax"
          placeholder={t('details.setIdsPlaceholder')}
        />
      </Grid2>
    </Grid2>
  );
}
