import { useEffect, useState } from 'react';

import { getShipmentsCountByStatus } from '../services/api/shipping-api';
import { BookingStatus } from '../utils';

export function useSelectOrFetchShipmentCount(statusFilters: BookingStatus[], depotId?: number) {
  const [statusCount, setStatusCount] = useState<Record<BookingStatus, number> | {}>({});

  useEffect(() => {
    const fetchData = async () => {
      getShipmentsCountByStatus(statusFilters, depotId).then(({ data }) => {
        const statusCount: Record<BookingStatus, number> | {} = {};
        data?.shipment_status_count?.forEach((value) => {
          statusCount[value.status] = value.count;
        });
        statusCount['all_shipments'] = data.total_count;
        setStatusCount(statusCount);
      });
    };

    fetchData();
  }, []);

  return { statusCount };
}
