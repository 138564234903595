import { TFunction } from 'i18next';

import { UNSELECTED_ROW_CLASSNAME } from '../../components/data-grid/utils';
import { InvoiceDetails, InvoiceGrid, InvoiceGridParams } from '../../interfaces/invoice';
import { LabelValue } from '../../interfaces/label-value';
import { DeliveryMode } from '../../utils';
import { InvoiceStatus, InvoiceType } from '../../utils/constants/invoice';
import { getDateUsingFormat, strToNumber } from '../../utils/functions';
import { roundUp } from '../../utils/functions/numbers';

const MAX_SELECTED_ROWS = 3;
const RENTAL_TYPE_TO_NAME = {
  short: 'VAML2CT',
  long: 'VAML2LT',
  gps: 'VAML2GPS',
};

export function mapToInvoiceFilterFromArrays(valueChanges: Record<string, any>): InvoiceGridParams {
  return Object.entries(valueChanges).reduce((currentItem, [key, value]) => {
    const addValue = {};
    if (['po_reference'].includes(key)) {
      addValue[key] = value.split(',').filter((element: number) => !!element);
    } else if (['set_id', 'invoice_id', 'licensee_number'].includes(key)) {
      const fieldIdsNumber: number[] = value
        .split(',')
        .map((element: string) => {
          const num = strToNumber(element) as number;
          return isNaN(num) ? null : num;
        })
        .filter((element: number) => !!element);
      if (key === 'invoice_id') {
        addValue['id'] = fieldIdsNumber;
      } else {
        addValue[key] = fieldIdsNumber;
      }
    } else {
      addValue[key] = value;
    }
    return {
      ...currentItem,
      ...addValue,
    };
  }, {}) as InvoiceGridParams;
}

export function getInvoiveStatusOptions(
  i18n: TFunction<string, undefined>,
  currentStatus: InvoiceStatus
): LabelValue[] {
  switch (currentStatus) {
    case InvoiceStatus.L2_GENERATED:
      return [{ value: InvoiceStatus.PAID, label: i18n(`invoiceStatus.${InvoiceStatus.PAID}`) }];
    case InvoiceStatus.PAID:
      return [
        {
          value: InvoiceStatus.L2_GENERATED,
          label: i18n(`invoiceStatus.${InvoiceStatus.L2_GENERATED}`),
        },
      ];
    case InvoiceStatus.READY:
      return [
        {
          value: InvoiceStatus.TO_BE_TREATED,
          label: i18n(`invoiceStatus.${InvoiceStatus.TO_BE_TREATED}`),
        },
      ];
    default:
      return [];
  }
}

export function getInvoiceSelectedState(
  row: InvoiceGrid,
  currentSelectedInvoices: InvoiceGrid[],
  currentSelectedLicensee: number,
  currentSelectedPo: string
): string {
  let className = '';
  if (
    row.invoice_status !== InvoiceStatus.READY ||
    ![InvoiceType.BOOKING, InvoiceType.GPS].includes(row.invoice_type)
  ) {
    className = UNSELECTED_ROW_CLASSNAME;
  } else if (currentSelectedInvoices.length === MAX_SELECTED_ROWS) {
    className = currentSelectedInvoices.includes(row) ? '' : UNSELECTED_ROW_CLASSNAME;
  } else if (currentSelectedLicensee && currentSelectedLicensee !== row.licensee_number) {
    className = UNSELECTED_ROW_CLASSNAME;
  } else if (currentSelectedPo && currentSelectedPo !== row.po_reference) {
    className = UNSELECTED_ROW_CLASSNAME;
  }

  return className;
}

export function getGenerel2FileName(rows: InvoiceGrid[]) {
  const { rental_type, invoice_type } = rows[0];
  const formattedDate: string = getDateUsingFormat(new Date(), 'yyyyMMddHHmmssSSS');

  return `${
    RENTAL_TYPE_TO_NAME[invoice_type === InvoiceType.GPS ? 'gps' : rental_type] || 'error'
  }_${formattedDate}.txt`;
}

export function getGenerel2FileNameZip(rows: InvoiceGrid[]) {
  const { rental_type } = rows[0];
  const formattedDate: string = getDateUsingFormat(new Date(), 'yyyyMMddHHmmssSSS');

  return `${RENTAL_TYPE_TO_NAME[rental_type] || 'error'}_${formattedDate}.zip`;
}

export function pipeInvoiceDetailsFields(invoiceData: InvoiceDetails): InvoiceDetails {
  const isClickAndCollect = invoiceData.invoice.delivery_mode === DeliveryMode.ClicknCollect;

  return {
    licensee: invoiceData.licensee,
    invoice: {
      ...invoiceData.invoice,
      admin_fees: roundUp(invoiceData?.invoice?.admin_fees || 0, 2),
      comments: invoiceData.invoice.comments || '',
      price_per_day: roundUp(invoiceData?.invoice?.price_per_day || 0, 2),
      export_weight: roundUp(invoiceData?.invoice?.export_weight || 0, 2),
      import_weight: roundUp(invoiceData?.invoice?.import_weight || 0, 2),
      admin_charges: roundUp(invoiceData?.invoice?.admin_charges || 0, 2),
      import_transport_charges: isClickAndCollect
        ? 0
        : roundUp(invoiceData?.invoice?.import_transport_charges, 2),
      export_transport_charges: isClickAndCollect
        ? 0
        : roundUp(invoiceData?.invoice?.export_transport_charges, 2),
      fuel_charges: roundUp(invoiceData?.invoice?.fuel_charges || 0, 2),
      damage_surcharge: roundUp(invoiceData?.invoice?.damage_surcharge || 0, 2),
      missing_gauge_surcharge: roundUp(invoiceData?.invoice?.missing_gauge_surcharge || 0, 2),
      start_date: new Date(invoiceData.invoice.start_date) as any,
      end_date: new Date(
        invoiceData.invoice.end_date || invoiceData.invoice.booking_end_date
      ) as any,
    },
  };
}
