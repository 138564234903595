// istanbul ignore file

export const userEndpoints = {
  users_search_by_email: (query) => `/users/email/${query}`,
  users: '/users',
  users_by_id: (user_id) => `/users/${user_id}`,
  users_by_type: (licensee_type) => `/users-by-type/${licensee_type}`,
  users_listing: '/users-listing',
  licensee_royalty_rate: (licensee_number) => `/licensees/${licensee_number}/royalty-rate`,
  licensee_users: '/licensee-users',
  change_user_status: (user_id) => `/users/${user_id}`,
  user_activity: (user_id) => `/user-activity/${user_id}`,
  user_roles: '/users-roles',
  update_user_roles: '/update-user-roles',
  roles: '/roles',
  licensees_users_roles: (licensee_number) => `/licensees/${licensee_number}/users-roles`,
};
