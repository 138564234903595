import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import GaugeModelIcon from '../../../assets/icons/icon-gauge.svg';
import { SetGaugeModel } from '../../../interfaces/set-creation';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { GridStatus } from '../../data-grid/utils';
import { DialogComponent } from '../../setModel/DialogComponent';
import { FormTextField } from '../../shared';

export interface DialogInspectProps {
  handleConfirm: Function;
  setNb: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 830px;
`;

/* istanbul ignore next */
const dialogInspectSetCols: GridColDef[] = [
  {
    field: 'designation_vam',
    headerName: 'SET CONTENT',
    minWidth: 240,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'drawing_number',
    headerName: 'DRAWING',
    minWidth: 90,
  },
  {
    field: 'reference_number',
    headerName: 'REF. NUMBER',
    minWidth: 90,
  },
  {
    field: 'serial_number',
    headerName: 'SERIAL N°',
    minWidth: 100,
    renderCell: /* istanbul ignore next */ ({ value }) => value || '-',
  },
  {
    field: 'is_gauge_required',
    headerName: 'REQ.',
    minWidth: 70,
    renderCell: ({ row }) => (row.is_gauge_required ? 'Yes' : 'No'),
  },
];

export function DialogInspect({ handleConfirm, setNb }: DialogInspectProps) {
  const { t } = useTranslation('gauge');
  const { getValues } = useFormContext();
  const set = getValues('sets')?.[setNb];
  const items = set?.gauges || [];
  const [gauges, setGauges] = React.useState<SetGaugeModel[]>(items);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleConfirmDialog = (gauges: SetGaugeModel[], setNb: number) => {
    handleConfirm(gauges, setNb);
    handleCloseDialog();
  };

  return (
    <DialogComponent
      title={t('makeReturn.inspectDialog.title')}
      subTitle={t('makeReturn.inspectDialog.subtitle')}
      icon={<></>}
      searchLabelBtn={t('makeReturn.inspectDialog.button')}
      handleOpenDialog={handleOpenDialog}
      buttonVariant="text"
      isOpen={isOpen}
      confirmSelection={() => handleConfirmDialog(gauges, setNb)}
      handleCloseDialog={handleCloseDialog}
      confirmLabelBtn={t('makeReturn.inspectDialog.confirmButton')}
      headerIcon={<img src={GaugeModelIcon} alt="gauge-model-icon" />}
      withCloseButton
      buttonProps={{ size: 'small' }}
    >
      <Container>
        <VamDataGrid
          data-testid="dialog-inspect-data-grid"
          showRowLeftThickBorder
          columns={dialogInspectSetCols.concat([
            {
              field: 'is_present',
              headerName: 'PRESENT',
              /* istanbul ignore next */
              renderCell(params) {
                return (
                  <Checkbox
                    checked={params.row.is_present}
                    onChange={(event) => {
                      setGauges(
                        gauges.map((gauge) => {
                          if (gauge.id === params.row.id) {
                            return {
                              ...gauge,
                              is_present: event.target.checked,
                              grid_status:
                                !params.row.is_damaged && event.target.checked
                                  ? GridStatus.OK
                                  : GridStatus.KO,
                            };
                          }
                          return gauge;
                        })
                      );
                    }}
                  />
                );
              },
            },
            {
              field: 'is_damaged',
              headerName: 'DAMAGES',
              /* istanbul ignore next */
              renderCell(params) {
                return (
                  <Checkbox
                    checked={params.row.is_damaged}
                    onChange={(event) => {
                      setGauges(
                        gauges.map((gauge) => {
                          if (gauge.id === params.row.id) {
                            return {
                              ...gauge,
                              is_damaged: event.target.checked,
                              grid_status:
                                params.row.is_present && !event.target.checked
                                  ? GridStatus.OK
                                  : GridStatus.KO,
                            };
                          }
                          return gauge;
                        })
                      );
                    }}
                  />
                );
              },
            },
          ])}
          rows={gauges}
          hideFooter
          autoHeight
          disableRowSelectionOnClick
          disableColumnMenu
        />
        <FormTextField
          name={`sets.${setNb}.comments`}
          label={t('makeReturn.inspectDialog.comments')}
          multiline
          variant="outlined"
          rows={3}
          fullWidth
          inputDataTestId="inspect-dialog-comments-textfield"
        />
      </Container>
    </DialogComponent>
  );
}
