import {
  InformationBox,
  SectionTitle,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import {
  Checkbox,
  FormControlLabel,
  Grid2,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks';
import { InvoiceDetail } from '../../interfaces/invoice';
import { BookingPriceParams } from '../../interfaces/veg';
import { getDhlTransportPrice } from '../../services/api/shipping-api';
import { getBookingPrice } from '../../services/api/veg-api';
import { selectDepotId } from '../../services/store/userSlice';
import { $grey20, $primaryBlack, $primaryBrand, $primaryNavy } from '../../styles/colors';
import { RentalType, ShippingSource, VegLocation } from '../../utils';
import { zones } from '../../utils/constants/countries/countries_code_alpha_2';
import { ADMIN_FEE, InvoiceStatus, InvoiceType } from '../../utils/constants/invoice';
import {
  getDifferenceInDays,
  isNotNullOrUndefined,
  isUndefinedOrNull,
} from '../../utils/functions';
import { convertStringToNumber, roundUp } from '../../utils/functions/numbers';
import { ContainerCard, FormDatePicker, FormSelect, FormTextField } from '../shared';
import { SubTotalInvoice } from './SubTotalInvoice';
import {
  AddBidentPrice,
  SUBTOTAL_KEY,
  SUBTOTAL_VALUE_KEY,
  applyUpdateInvoiceFields,
  getSubTotalSecondPrice,
  handleSwitchRentalType,
  updatePriceChargesExpedition,
} from './utils';

export interface BillingProps {
  invoice: InvoiceDetail;
}

export function Billing({ invoice }: BillingProps) {
  const { t } = useTranslation('invoice');

  const methods = useFormContext();
  const { setValue, trigger, getValues } = methods;
  const [reservationAmount, setReservationAmount] = React.useState<number>(0);
  const [exportTransportCharges, setExportTransportCharges] = React.useState<number>(
    invoice.export_transport_charges
  );
  const [importTransportCharges, setImportTransportCharges] = React.useState<number>(
    invoice.import_transport_charges
  );
  const [stepHookOneComplete, setStepHookOneComplete] = React.useState(false);

  const [freeOfCharges, setFreeOfCharges] = React.useState<boolean>(invoice.free_of_charges);
  const [adminFee, setAdminFee] = React.useState<number>(invoice.admin_fees || 0);
  const [minEndDate, setMinEndDate] = React.useState<Date>(new Date(invoice.start_date));
  const [maxStartDate, setMaxStartDate] = React.useState<Date>(
    invoice.end_date ? new Date(invoice.end_date) : new Date()
  );
  const disableForm = invoice.invoice_status !== InvoiceStatus.TO_BE_TREATED;
  const transportAndSetDisable =
    disableForm || invoice.invoice_type === InvoiceType.GPS || invoice.is_ltr_extended;
  // authorize only depotId 1 to make switch str to ltr
  const depotId = useAppSelector(selectDepotId);

  React.useEffect(() => {
    const getReservationPrice = async () => {
      if (invoice.rental_type === RentalType.Short) {
        const reservation_amount = roundUp(
          getValues('days_in_hiring') * getValues('price_per_day'),
          2
        );
        setValue('reservation_amount', reservation_amount);
        setReservationAmount(reservation_amount);
      } else {
        const {
          data: { veg_price },
        } = await getBookingPrice({
          connection: invoice.connection,
          od_inch: invoice.od_inch,
          rental_type: RentalType.Long,
        });
        setValue('reservation_amount', veg_price);
        setReservationAmount(veg_price);
      }
      trigger('reservation_amount');
    };

    if (invoice.invoice_type === InvoiceType.BOOKING) {
      getReservationPrice();
      // if export or import transport_charges is null the we fetch the value this is
      // occur only on the first time when the invoice is created
      if (isUndefinedOrNull(invoice?.export_transport_charges)) {
        updateTransportPrice(
          invoice.export_transport_zone,
          invoice.export_weight,
          ShippingSource.Dispatch
        );
      }
      if (isUndefinedOrNull(invoice?.import_transport_charges)) {
        updateTransportPrice(
          invoice.import_transport_zone,
          invoice.import_weight,
          ShippingSource.Return
        );
      }
    }
    setStepHookOneComplete(true);
  }, []);

  React.useEffect(() => {
    setValue('price', getTotalInvoicePrice());
    trigger('price');
  }, [reservationAmount]);

  React.useEffect(() => {
    if (getValues('rental_type') === RentalType.Short) {
      const days = getDifferenceInDays(minEndDate, maxStartDate);
      setValue('days_in_hiring', days);
      const reservation_amount = roundUp(days * getValues('price_per_day'), 2);
      setValue('reservation_amount', reservation_amount);
      setReservationAmount(reservation_amount);
    }
  }, [minEndDate, maxStartDate]);

  React.useEffect(() => {
    const onFreeChargesChange = () => {
      if (freeOfCharges) {
        applyUpdateInvoiceFields(setValue, {});
        setValue('export_transport_charges', 0);
        setValue('import_transport_charges', 0);
        setImportTransportCharges(0);
        setExportTransportCharges(0);
        setAdminFee(0);
        setReservationAmount(0);
        updatePriceChargesExpedition(0, 0, setValue, setExportTransportCharges);
      } else {
        applyUpdateInvoiceFields(setValue, {
          admin_charges: invoice.admin_charges,
          fuel_charges: invoice.fuel_charges,
          damage_surcharge: invoice.damage_surcharge,
          missing_gauge_surcharge: invoice.missing_gauge_surcharge,
          price_per_day: invoice.price_per_day,
          bident_price: invoice.bident_price,
          export_transport_charges: invoice.export_transport_charges,
          import_transport_charges: invoice.import_transport_charges,
          admin_fees: invoice.admin_fees,
        });
        setMinEndDate(new Date(invoice.start_date));
        if (getValues('rental_type') === RentalType.Long) {
          setReservationAmount(invoice.price);
        }
      }
    };
    // for business logic and rules we have to wait the first hook finish
    if (stepHookOneComplete) {
      onFreeChargesChange();
    }
  }, [freeOfCharges, stepHookOneComplete]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    const { id: name, value } = event.target;
    // To convert the value to float before validation
    const numericValue = roundUp(parseFloat(value), 2);
    setValue(name, value !== '' ? numericValue : 0);
    if (value !== '') {
      switch (name) {
        case 'price_per_day':
          setReservationAmount(roundUp(numericValue * getValues('days_in_hiring'), 2));
          break;
        case 'export_weight':
          updateTransportPrice(
            getValues('export_transport_zone'),
            numericValue,
            ShippingSource.Dispatch
          );
          break;
        case 'import_weight':
          updateTransportPrice(
            getValues('import_transport_zone'),
            numericValue,
            ShippingSource.Return
          );
          break;
        case 'export_transport_charges':
          updatePriceChargesExpedition(
            numericValue,
            getSubTotalSecondPrice(getValues('rental_type'), 'import_transport_charges', getValues),
            setValue,
            setExportTransportCharges
          );
          break;
        case 'import_transport_charges':
          updatePriceChargesExpedition(
            numericValue,
            getSubTotalSecondPrice(getValues('rental_type'), 'export_transport_charges', getValues),
            setValue,
            setImportTransportCharges
          );
          break;
      }
    } else {
      switch (name) {
        case 'price_per_day':
          setReservationAmount(0);
          break;
        case 'export_weight':
          setValue('export_transport_charges', 0);
          setExportTransportCharges(0);
          break;
        case 'import_weight':
          setValue('import_transport_charges', 0);
          setImportTransportCharges(0);
          break;
      }
    }
    trigger(name);
  };

  const updateTransportPrice = async (
    transportZone: number,
    weight: number,
    shippingSource: ShippingSource
  ) => {
    // fixme in gps because sometimes we don't have all informations to make this call
    if (isNotNullOrUndefined(transportZone) && !freeOfCharges) {
      const { data: price } = await getDhlTransportPrice(transportZone, weight, shippingSource);

      if (shippingSource === ShippingSource.Dispatch) {
        setValue('export_transport_charges', price);
        updatePriceChargesExpedition(
          price,
          getSubTotalSecondPrice(getValues('rental_type'), 'import_transport_charges', getValues),
          setValue,
          setExportTransportCharges
        );
        trigger('export_transport_charges');
      } else {
        setValue('import_transport_charges', price);
        updatePriceChargesExpedition(
          price,
          getSubTotalSecondPrice(getValues('rental_type'), 'export_transport_charges', getValues),
          setValue,
          setImportTransportCharges
        );
        trigger('import_transport_charges');
      }
    } else {
      setValue('export_transport_charges', 0);
      setExportTransportCharges(0);
      setValue('import_transport_charges', 0);
      setImportTransportCharges(0);
    }
  };

  const handleSwitchToLtr = async (type: RentalType) => {
    const switchToLtr = invoice.rental_type === RentalType.Short;
    const {
      data: { veg_price },
    } = await getBookingPrice({
      connection: invoice.connection,
      od_inch: invoice.od_inch,
      rental_type: RentalType.Long,
    } as BookingPriceParams);
    const reservation_amount = roundUp(veg_price, 2);
    setValue('reservation_amount', reservation_amount);
    setReservationAmount(reservation_amount);
    setValue('admin_fees', ADMIN_FEE);
    setAdminFee(ADMIN_FEE);
    handleSwitchRentalType(setValue, type, switchToLtr, trigger);
  };

  const getTotalInvoicePrice = () => {
    if (!invoice.rental_type) {
      let total = 0;
      if (freeOfCharges) {
        return total;
      } else if (invoice.admin_fees) {
        total = invoice.price || 0;
      } else {
        total = (invoice.price || 0) + adminFee;
      }
      setValue('price', total);
      return total;
    }
    const rentalTypeChoosed = getValues('rental_type');
    const basePrice =
      reservationAmount +
      convertStringToNumber(getValues('admin_charges')) +
      convertStringToNumber(getValues('fuel_charges')) +
      convertStringToNumber(getValues('export_transport_charges')) +
      convertStringToNumber(getValues('missing_gauge_surcharge')) +
      convertStringToNumber(getValues('damage_surcharge'));

    let additionalCharges = 0;
    if (rentalTypeChoosed === RentalType.Short) {
      additionalCharges += convertStringToNumber(getValues('import_transport_charges'));
    }
    const totalPrice = basePrice + additionalCharges + adminFee;
    const finalPrice = roundUp(totalPrice + convertStringToNumber(getValues('bident_price')), 2);
    setValue('price', finalPrice);

    return freeOfCharges ? 0 : finalPrice;
  };

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={
        <SectionTitle
          title={t('invoiceDetails.billing.title')}
          description={t('invoiceDetails.billing.description')}
        />
      }
      actionHeaderNode={
        invoice.rental_type ? (
          <ToggleButtonGroup
            color="primary"
            value={getValues('rental_type')}
            exclusive
            size="small"
            aria-label="Platform"
            sx={{
              marginTop: '10px',
            }}
            disabled={disableForm}
          >
            <ToggleButton
              color="primary"
              value="short"
              data-testid="toggle-rental-short"
              disabled={invoice.rental_type === RentalType.Long}
              onClick={(_, type: string) => {
                setReservationAmount(
                  roundUp(getValues('days_in_hiring') * getValues('price_per_day'), 2)
                );
                setAdminFee(0);
                handleSwitchRentalType(setValue, type, false, trigger);
              }}
            >
              {t('invoiceDetails.billing.shortRental')}
            </ToggleButton>
            <ToggleButton
              color="primary"
              value="long"
              data-testid="toggle-rental-long"
              onClick={(_, type: string) => {
                handleSwitchToLtr(type as RentalType);
              }}
              disabled={
                invoice.invoice_type !== InvoiceType.BOOKING ||
                depotId !== +VegLocation.AulnoyeAymeries
              }
            >
              {t('invoiceDetails.billing.longRental')}
            </ToggleButton>
          </ToggleButtonGroup>
        ) : null
      }
    >
      <Grid2 container rowSpacing={2} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid2 size={{ xs: 12 }} display="flex" justifyContent="start">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(_, checked: boolean) => {
                  setValue('free_of_charges', checked);
                  setFreeOfCharges(checked);
                }}
                data-testid="free-of-charge-checkbox"
                disabled={disableForm}
                checked={freeOfCharges}
              />
            }
            label={
              <Typo
                className="tw-whitespace-nowrap"
                variant="body1"
                fontWeight={500}
                color={$primaryBlack}
              >
                {t('invoiceDetails.billing.free')}
              </Typo>
            }
          />
        </Grid2>
        {invoice.rental_type ? (
          <>
            <Grid2 size={{ xs: 6 }}>
              <FormDatePicker
                label={`${t('invoiceDetails.billing.startDate')}*`}
                name="start_date"
                handleChange={(startDate: Date, controlName: string) => {
                  setValue(controlName, startDate);
                  trigger(controlName);
                  setMinEndDate(startDate);
                }}
                maxDate={maxStartDate}
                dataTestId="datepicker-startdate"
                disabled={disableForm}
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormDatePicker
                label={`${t('invoiceDetails.billing.endDate')}*`}
                name="end_date"
                minDate={minEndDate}
                handleChange={(endDate: Date, controlName: string) => {
                  setValue(controlName, endDate);
                  trigger(controlName);
                  setMaxStartDate(endDate);
                }}
                dataTestId="datepicker-enddate"
                disabled={disableForm}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-5">
                <Typo
                  className="tw-whitespace-nowrap"
                  variant="body1"
                  fontWeight={500}
                  color={$primaryNavy}
                >
                  {t('invoiceDetails.billing.invoicingDetails')}
                </Typo>
                <div className="tw-h-0.5 tw-w-full tw-bg-gray-200" />
              </div>
            </Grid2>

            {/* show days in hiring only in SHORT TERM */}
            {getValues('rental_type') === RentalType.Short ? (
              <>
                <Grid2 size={{ xs: 3 }}>
                  <FormTextField
                    id="days_in_hiring"
                    name="days_in_hiring"
                    label={t('invoiceDetails.billing.days')}
                    variant="outlined"
                    size="small"
                    value={getValues('days_in_hiring')}
                    data-testid="days-in-hiring-textfield"
                    disabled
                  />
                </Grid2>
                <Grid2 size={{ xs: 3 }}>
                  <FormTextField
                    id="price_per_day"
                    name="price_per_day"
                    label={t('invoiceDetails.billing.pricePerDay')}
                    variant="outlined"
                    size="small"
                    type="number"
                    onBlur={handleBlur}
                    InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                    inputDataTestId="price-per-day-textfield"
                    disabled={disableForm}
                  />
                </Grid2>
              </>
            ) : null}

            <Grid2
              size={{ xs: getValues('rental_type') === RentalType.Long ? 12 : 6 }}
              display="flex"
              justifyContent="flex-end"
            >
              <SubTotalInvoice subTotalPrice={reservationAmount} />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-5">
                <Typo
                  className="tw-whitespace-nowrap"
                  variant="body1"
                  fontWeight={500}
                  color={$primaryNavy}
                >
                  {t('invoiceDetails.billing.transportCharges')}
                </Typo>
                <div className="tw-h-0.5 tw-w-full tw-bg-gray-200" />
              </div>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <FormTextField
                id="admin_charges"
                name="admin_charges"
                label={t('invoiceDetails.billing.adminCharges')}
                variant="outlined"
                size="small"
                type="number"
                onBlur={handleBlur}
                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                disabled
                data-testid="admin-charges-textfield"
              />
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <FormTextField
                id="fuel_charges"
                name="fuel_charges"
                label={t('invoiceDetails.billing.fuelCharges')}
                variant="outlined"
                size="small"
                type="number"
                onBlur={handleBlur}
                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                disabled
                data-testid="fuel-charges-textfield"
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }} display="flex" justifyContent="flex-end">
              <SubTotalInvoice
                subTotalPrice={roundUp(getValues('admin_charges') + getValues('fuel_charges'), 2)}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <Typo className="tw-whitespace-nowrap" fontWeight={500} color={$grey20}>
                {t('invoiceDetails.billing.export')}
              </Typo>
            </Grid2>
            <Grid2 size={{ xs: 3 }} maxHeight={56}>
              <FormSelect
                id="select-export-zone"
                data-testid="select-export-zone"
                name="export_transport_zone"
                variant="outlined"
                removeEmptyValue
                label={t('invoiceDetails.billing.zone')}
                handleChange={(value, _) => {
                  updateTransportPrice(value, getValues('export_weight'), ShippingSource.Dispatch);
                }}
                options={zones}
                disabled={transportAndSetDisable}
              />
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <FormTextField
                id="export_weight"
                name="export_weight"
                label={t('invoiceDetails.billing.weight')}
                variant="outlined"
                size="small"
                type="number"
                onBlur={handleBlur}
                InputProps={{ endAdornment: <InputAdornment position="end">Kg</InputAdornment> }}
                inputDataTestId="export-weight-textfield"
                disabled={transportAndSetDisable}
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }} justifyContent="flex-end">
              <SubTotalInvoice
                subTotalPrice={exportTransportCharges}
                formNameControl="export_transport_charges"
                transportAndSetDisable={transportAndSetDisable}
                handleBlur={handleBlur}
              />
            </Grid2>

            {/* show import transport price only in SHORT TERM */}
            {getValues('rental_type') === RentalType.Short ? (
              <>
                <Grid2 size={{ xs: 12 }}>
                  <Typo className="tw-whitespace-nowrap" fontWeight={500} color={$grey20}>
                    {t('invoiceDetails.billing.import')}
                  </Typo>
                </Grid2>
                <Grid2 size={{ xs: 3 }} maxHeight={56}>
                  <FormSelect
                    id="select-import-zone"
                    data-testid="select-import-zone"
                    name="import_transport_zone"
                    variant="outlined"
                    label={t('invoiceDetails.billing.zone')}
                    removeEmptyValue
                    handleChange={(value, _) => {
                      updateTransportPrice(
                        value,
                        getValues('import_weight'),
                        ShippingSource.Return
                      );
                    }}
                    options={zones}
                    disabled={transportAndSetDisable}
                  />
                </Grid2>
                <Grid2 size={{ xs: 3 }}>
                  <FormTextField
                    id="import_weight"
                    name="import_weight"
                    label={t('invoiceDetails.billing.weight')}
                    variant="outlined"
                    size="small"
                    type="number"
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                    }}
                    inputDataTestId="import-weight-textfield"
                    disabled={transportAndSetDisable}
                  />
                </Grid2>
                <Grid2 size={{ xs: 6 }} display="flex" justifyContent="flex-end">
                  <SubTotalInvoice
                    subTotalPrice={importTransportCharges}
                    formNameControl="import_transport_charges"
                    transportAndSetDisable={transportAndSetDisable}
                    handleBlur={handleBlur}
                  />
                </Grid2>
              </>
            ) : null}
            <Grid2 size={{ xs: 12 }}>
              <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-5">
                <Typo
                  className="tw-whitespace-nowrap"
                  variant="body1"
                  fontWeight={500}
                  color={$primaryNavy}
                >
                  {t('invoiceDetails.billing.setCondition')}
                </Typo>
                <div className="tw-h-0.5 tw-w-full tw-bg-gray-200" />
              </div>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <FormTextField
                id="damage_surcharge"
                name="damage_surcharge"
                label={t('invoiceDetails.billing.damageFee')}
                variant="outlined"
                size="small"
                type="number"
                onBlur={handleBlur}
                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                data-testid="damage-fee-textfield"
                disabled={transportAndSetDisable}
              />
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <FormTextField
                id="missing_gauge_surcharge"
                name="missing_gauge_surcharge"
                label={t('invoiceDetails.billing.missingFee')}
                variant="outlined"
                size="small"
                type="number"
                onBlur={handleBlur}
                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                data-testid="missing-fee-textfield"
                disabled={transportAndSetDisable}
              />
            </Grid2>
            <Grid2 size={{ xs: 6 }} justifyContent="flex-end">
              <SubTotalInvoice
                subTotalPrice={
                  convertStringToNumber(getValues('missing_gauge_surcharge')) +
                  convertStringToNumber(getValues('damage_surcharge'))
                }
              />
            </Grid2>
          </>
        ) : null}

        <Grid2 size={{ xs: 12, md: 6 }} offset={{ xs: 0, md: 6 }} justifyContent="flex-end">
          <div className="tw-flex tw-flex-col">
            <div className="tw-flex tw-flex-col">
              <InformationBox color={$primaryBrand}>
                <div className="tw-flex tw-w-full tw-justify-between tw-h-8 tw-items-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(_, checked: boolean) => {
                          const value = checked ? ADMIN_FEE : 0;
                          setValue('admin_fees', value);
                          setAdminFee(value);
                        }}
                        checked={!!adminFee}
                        disabled={freeOfCharges || disableForm}
                      />
                    }
                    label={
                      <Typo className="tw-whitespace-nowrap" variant="body1" color={$primaryBlack}>
                        {t('invoiceDetails.billing.applyAdminFee')}
                      </Typo>
                    }
                  />
                  <Typo
                    className="tw-whitespace-nowrap"
                    variant="labelForStatus"
                    fontWeight={500}
                    fontStyle="italic"
                    color={$primaryNavy}
                  >
                    {t(SUBTOTAL_KEY)}
                  </Typo>
                  <Typo
                    className="tw-whitespace-nowrap"
                    variant="labelForStatus"
                    fontWeight={500}
                    fontStyle="normal"
                    color={$primaryNavy}
                  >
                    {t(SUBTOTAL_VALUE_KEY, { value: adminFee ? ADMIN_FEE : 0 })}
                  </Typo>
                </div>
              </InformationBox>
            </div>

            <AddBidentPrice invoice={invoice} />

            {invoice.invoice_type === InvoiceType.NEW_TOKEN ? (
              <div className="tw-flex tw-flex-col -tw-mt-2">
                <InformationBox color={$primaryBrand}>
                  <div className="tw-flex tw-w-full tw-justify-between tw-h-8 tw-items-center">
                    <span className="tw-w-40" />
                    <Typo
                      className="tw-whitespace-nowrap"
                      variant="labelForStatus"
                      fontWeight={500}
                      fontStyle="italic"
                      color={$primaryNavy}
                    >
                      {t('invoiceDetails.billing.addedTokens')}
                    </Typo>
                    <Typo
                      className="tw-whitespace-nowrap"
                      variant="labelForStatus"
                      fontWeight={500}
                      color={$primaryNavy}
                    >
                      {invoice.new_tokens_added}
                    </Typo>
                  </div>
                </InformationBox>
              </div>
            ) : null}

            <div className="tw-flex tw-flex-col -tw-mt-2">
              <InformationBox color={$primaryBrand}>
                <div className="tw-flex tw-w-full tw-justify-between tw-h-8 tw-items-center">
                  <Typo
                    className="tw-whitespace-nowrap"
                    variant="body1"
                    fontWeight={700}
                    color={$primaryNavy}
                  >
                    {t('invoiceDetails.billing.total')}
                  </Typo>
                  <Typo
                    className="tw-whitespace-nowrap"
                    variant="body1"
                    fontWeight={700}
                    color={$primaryNavy}
                  >
                    {t(SUBTOTAL_VALUE_KEY, {
                      value: getTotalInvoicePrice(),
                    })}
                  </Typo>
                </div>
              </InformationBox>
            </div>
          </div>
        </Grid2>
      </Grid2>
    </ContainerCard>
  );
}
