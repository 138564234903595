import {
  InformationBox,
  SectionTitle,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import { Grid2 } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFetchPricingPlan } from '../../hooks/useFetchPricingPlan';
import { getTitleContext } from '../../pages/royalties/utils';
import { $grey20, $primaryNavy } from '../../styles/colors';
import { Currency } from '../../utils';
import { formatPriceLocaleAware } from '../../utils/functions/price';
import { VamDataGrid } from '../data-grid/VamDataGrid';
import { ContainerCard } from '../shared';
import {
  billingCols,
  calculateAllRates,
  calculateTotal,
  generateRows,
  groupSidesByAttributes,
} from './utils';

export function BillingDetails() {
  const { t } = useTranslation('royalties', { keyPrefix: 'createJob.billingDetails' });
  const methods = useFormContext();
  const { watch, setValue } = methods;
  const { plan } = useFetchPricingPlan();

  const sides = watch('sides');
  const numberOfParts = watch('number_of_parts');
  const gradeFamily = watch('grade_family');
  const partType = watch('type_of_part');

  const grouped = groupSidesByAttributes(sides);

  const allRates = React.useMemo(
    () => calculateAllRates(grouped, numberOfParts, plan, gradeFamily, partType),
    [grouped, plan, sides, numberOfParts, gradeFamily, partType]
  );

  const rows = React.useMemo(
    () => generateRows(sides, allRates, numberOfParts, plan?.currency),
    [sides, allRates, numberOfParts, plan?.currency]
  );

  const total = React.useMemo(() => {
    const price = calculateTotal(rows);
    setValue('total_price', price);
    return price;
  }, [rows]);

  const titleContext = getTitleContext(sides.length);

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      containerCardClass="bg-color-white"
      dataTestId="job-billing-container"
      avatarHeaderNode={<SectionTitle title={t('title')} description={t('description')} />}
    >
      <Grid2 container columnSpacing={{ xs: 2, sm: 3, md: 4 }} rowGap={1}>
        {rows.map((row, sideNb) => (
          <Grid2 key={row.id} size={{ xs: 12 }}>
            <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-5 tw-mb-3">
              <Typo
                className="tw-whitespace-nowrap"
                variant="body1"
                fontWeight={500}
                color={$primaryNavy}
              >
                {t('sideNb', { index: sideNb + 1, context: titleContext })}
              </Typo>
              <div className="tw-h-0.5 tw-w-full tw-bg-gray-200 tw" />
            </div>
            <div className="tw-mb-4">
              <VamDataGrid
                data-testid="royalty-billing-data-grid"
                showRowLeftThickBorder
                columns={billingCols}
                rows={[row]}
                hideFooter
                disableRowSelectionOnClick
                disableColumnMenu
                rowHeight={40}
                height={100}
              />
            </div>
          </Grid2>
        ))}
      </Grid2>

      {rows.length > 0 && (
        <>
          <Grid2 size={{ xs: 12 }}>
            <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-5 tw-mb-3">
              <Typo
                className="tw-whitespace-nowrap"
                variant="body1"
                fontWeight={500}
                color={$grey20}
              >
                {t('summary')}
              </Typo>
              <div className="tw-w-full tw-border-0 tw-border-b tw-border-dashed tw-border-gray-200" />
            </div>
          </Grid2>
          <Grid2 size={{ xs: 4 }} offset={8} justifyContent="flex-end">
            <div className="tw-flex tw-flex-col -tw-mt-2">
              <InformationBox color={$primaryNavy}>
                <div className="tw-flex tw-w-full tw-justify-between tw-h-8 tw-items-center">
                  <Typo
                    className="tw-whitespace-nowrap"
                    variant="body1"
                    fontWeight={700}
                    color={$primaryNavy}
                  >
                    {t('total')}
                  </Typo>
                  <Typo
                    className="tw-whitespace-nowrap"
                    variant="body1"
                    fontWeight={700}
                    color={$primaryNavy}
                  >
                    {formatPriceLocaleAware(total, Currency[plan?.currency])}
                  </Typo>
                </div>
              </InformationBox>
            </div>
          </Grid2>
        </>
      )}
    </ContainerCard>
  );
}
