import { TFunction } from 'i18next';

export function getBreadcrumbRoutes(
  t: TFunction,
  fromPathname: string,
  businessNumber: string
): any[] {
  const lastSegment = fromPathname.substring(fromPathname.lastIndexOf('/') + 1);

  let breadcrumbRoutes: any[];
  switch (lastSegment) {
    case 'my-gauges':
      breadcrumbRoutes = [
        {
          breadcrumb: 'My Gauges',
          path: fromPathname,
        },
      ];
      break;

    case 'reservation-management':
      breadcrumbRoutes = [
        {
          breadcrumb: 'Reservation Management',
          path: fromPathname,
        },
      ];
      break;

    case 'rental-data':
      breadcrumbRoutes = [
        {
          breadcrumb: 'Rental Data',
          path: fromPathname,
        },
      ];
      break;

    case 'current-rentals':
      breadcrumbRoutes = [
        {
          breadcrumb: 'Current Rentals',
          path: fromPathname,
        },
      ];
      break;
  }

  breadcrumbRoutes?.push({
    breadcrumb: t('manageBookingDetail.title') + (businessNumber && ` - #${businessNumber}`),
    path: '#',
  });

  return breadcrumbRoutes;
}
