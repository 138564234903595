import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@digital-at-vallourec/steel-design-system-react';
import { Backdrop } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enIE } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';

import './App.scss';
import { ChooseCompanyDialog } from './components/user/ChooseCompanyDialog';
import { HandleErrorLoginDialog } from './components/user/HandleErrorLoginDialog';
import { useAppDispatch } from './hooks';
import { User, UsersMyVam } from './interfaces/user';
import RouterConfig from './router/router';
import { loadUserInfos, updateLastActivityDate } from './services/api/user-api';
import { setAllAxiosInstances } from './services/axios-services/utils';
import { setUser } from './services/store/userSlice';
import { $primaryWhite } from './styles/colors';
import { SHOW_SPINNER } from './utils/constants/custom-event';
import { isUndefinedOrNull } from './utils/functions';

function App() {
  const [openSpinner, setOpenSpinner] = React.useState(false);
  const [showChooseCompany, setShowChooseCompany] = React.useState(false);
  const [showErrorLogin, setShowErrorLogin] = React.useState<boolean>(false);
  const [accounts, setAccounts] = React.useState<UsersMyVam>({
    accounts: [],
    roles: [],
    permissions: [],
  });

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [loadUser, setLoadUser] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('home');
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    // Listen for this event on the window object
    window.addEventListener(SHOW_SPINNER, resetEventHandler);
    return () => {
      window.removeEventListener(SHOW_SPINNER, resetEventHandler);
    };
  });

  React.useEffect(() => {
    setLoadUser(false);
    const loadUserData = async () => {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        // Best practice it to use sdk getAccessTokenSilently()
        await setAllAxiosInstances(getAccessTokenSilently);

        loadUserInfos(accessToken)
          .then(({ data }) => {
            if (data.accounts.length > 1) {
              setShowChooseCompany(true);
              setAccounts(data);
            } else {
              setShowChooseCompany(false);
              const userLogged = data.accounts[0];
              if (isUndefinedOrNull(userLogged)) {
                setShowErrorLogin(true);
                return;
              }
              const userData = {
                ...userLogged,
                roles: data.roles || [],
                permissions: data.permissions || [],
                hasManyLicensees: false,
              };
              dispatch(setUser(userData));
              enqueueSnackbar(t('notification.login'), {
                preventDuplicate: true,
                variant: 'success',
              });
            }
          })
          .catch((error) => {
            console.error('error when we try to load user, ', error);
          })
          .finally(() => setLoadUser(true));
      }
    };
    loadUserData();
  }, [isAuthenticated]);

  const handleChoosedLicensee = async (licensee_number: number) => {
    const choosedCompany: User = accounts.accounts.filter(
      (acc) => acc.licensee_number === licensee_number
    )[0];
    const userData = {
      ...choosedCompany,
      hasManyLicensees: true,
      roles: accounts.roles || [],
      permissions: accounts.permissions || [],
    };
    dispatch(setUser(userData));
    setShowChooseCompany(false);
    await saveLastActivityDate(userData.id);
    enqueueSnackbar(t('notification.login'), {
      preventDuplicate: true,
      variant: 'success',
    });
  };

  const saveLastActivityDate = async (userId: number) => {
    await updateLastActivityDate(userId);
  };

  async function resetEventHandler({ detail }: any) {
    detail && (await setAllAxiosInstances(getAccessTokenSilently));
    setOpenSpinner(!!detail);
  }

  if (isAuthenticated && !loadUser) {
    return (
      <Backdrop
        sx={{ color: $primaryWhite, zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={true}
      >
        <Spinner />
      </Backdrop>
    );
  }

  if (showChooseCompany) {
    return (
      <ChooseCompanyDialog
        title={t('chooseCompany', { context: 'title' })}
        subTitle={t('chooseCompany', { context: 'description' })}
        showChooseCompany={showChooseCompany}
        handleChooseCompany={($event) => handleChoosedLicensee($event)}
        accounts={accounts.accounts}
      />
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enIE}>
      <RouterProvider router={RouterConfig} />
      <HandleErrorLoginDialog open={showErrorLogin} errorType="unknown" />
      <Backdrop
        sx={{ color: $primaryWhite, zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openSpinner}
      >
        <Spinner />
      </Backdrop>
    </LocalizationProvider>
  );
}

export default App;
