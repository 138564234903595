/* istanbul ignore file */
import { useAuth0 } from '@auth0/auth0-react';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PageLoader } from '../shared/PageLoader/PageLoader';
import { HandleErrorLoginDialog } from '../user/HandleErrorLoginDialog';

export const Auth0Callback = () => {
  const { handleRedirectCallback } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [showErrorLogin, setShowErrorLogin] = React.useState<boolean>(false);

  const searchParams = new URLSearchParams(location.search);

  const onRedirectCallback = (redirectLoginResult: RedirectLoginResult) => {
    const redirectUrl = redirectLoginResult?.appState?.returnTo
      ? `../${redirectLoginResult?.appState?.returnTo}`
      : null;

    navigate(redirectUrl || '/', {
      state: { from: location },
    });
  };

  useEffect(() => {
    async function handleAuth0Callback() {
      try {
        const data = await handleRedirectCallback();
        // you can navigate the user to the main area of the app.
        // or you can use the useHistory hook from 'react-router-dom' to redirect them to the intended page.
        onRedirectCallback(data || ({} as RedirectLoginResult));
      } catch (e) {
        if (searchParams.get('error') === 'access_denied') {
          setShowErrorLogin(true);
        }
        // todo when auth0 upgrade doc with data api router v6
        if (!e.toString().includes('Invalid state')) {
          console.error(e);
        }
      }
    }
    handleAuth0Callback();
  }, []);

  if (showErrorLogin) {
    return (
      <div>
        <HandleErrorLoginDialog open={showErrorLogin} errorType="unauthorized" />
      </div>
    );
  }

  // render some loading text while the callback is being processed
  return <PageLoader />;
};
