import { GridSortModel } from '@mui/x-data-grid';
import React from 'react';

import { statusFilters } from '../components/booking/shipment/utils';
import { ManageShipmentsResult } from '../interfaces/shipping';
import { getShipmentsByStatus } from '../services/api/veg-api';
import {
  BOOKING_STATUS_GRID_MAPPING,
  BookingStatus,
  DEFAULT_PAGE_SIZE,
  ShippingSource,
} from '../utils';
import { formatDate, isNotNullOrUndefined } from '../utils/functions';

export function useFetchShipmentsByStatus(
  status?: BookingStatus,
  sort?: GridSortModel,
  page?: number,
  shipping_source?: ShippingSource,
  licensee_number?: number,
  depot_id?: number
) {
  const [datagrid, setDatagrid] = React.useState<ManageShipmentsResult[]>([]);
  const [gridLoading, setGridLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setGridLoading(true);
      getShipmentsByStatus({
        licensee_number,
        filters_status: status ? [status] : statusFilters,
        pagination: {
          skip: isNotNullOrUndefined(page) ? DEFAULT_PAGE_SIZE * page : null,
          limit: isNotNullOrUndefined(page) ? DEFAULT_PAGE_SIZE : null,
        },
        sort,
        shipping_source,
        depot_id,
      })
        .then(({ data }) => {
          const dataGrid = data?.map((row, idx) => ({
            id: idx,
            ...row,
            shipment_date: formatDate(row.shipment_date, false),
            grid_status: BOOKING_STATUS_GRID_MAPPING[row.status],
          }));
          setDatagrid(dataGrid);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setGridLoading(false);
        });
    };
    fetchData();
  }, [status, page, sort]);

  return { datagrid, gridLoading };
}
