import {
  IncrementalComponent,
  Tooltip,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import InfoIcon from '@mui/icons-material/Info';
import {
  FormControl,
  FormControlLabel,
  Grid2,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DhlIcon from '../../../assets/icons/icon-dhl.svg';
import FedexIcon from '../../../assets/icons/icon-fedex.svg';
import { DeliveryMode, DispatchBy, ShippingSource } from '../../../utils';
import { getDhlAccountNumber } from '../../../utils/functions/shipping';
import { FormTextField } from '../../shared';

export interface ShippingInformationsProps {
  isReturn?: boolean;
  deliveryMode: DeliveryMode;
}

export function ShippingInformations({ isReturn, deliveryMode }: ShippingInformationsProps) {
  const { t } = useTranslation('gauge');
  const [nbBoxes, setNbBoxes] = React.useState<number>(1);
  const methods = useFormContext();
  const dhlAccountNumber = isReturn
    ? getDhlAccountNumber(ShippingSource.Return)
    : getDhlAccountNumber(ShippingSource.Dispatch);
  const { setValue, trigger, getValues } = methods;

  const handleIncrease = () => {
    const newValue = nbBoxes + 1;
    setValue('nb_boxes', newValue);
    trigger('nb_boxes');
    setNbBoxes(newValue);
  };

  const handleDecrease = () => {
    const newValue = nbBoxes - 1;
    setValue('nb_boxes', newValue);
    trigger('nb_boxes');
    setNbBoxes(newValue);
  };

  const handleDispatchByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('dispatch_by', e.target.value);
    if (e.target.value === DispatchBy.DHL) {
      setValue('account_number', dhlAccountNumber);
    } else {
      setValue('account_number', '');
    }
    trigger(['dispatch_by', 'account_number', 'air_way_bill']);
  };

  return (
    <Grid2 container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
      <Grid2 size={{ xs: 12 }}>
        <div className="tw-flex tw-flex-row tw-gap-20">
          <div className="tw-items-center tw-flex tw-flex-col tw-gap-2">
            <Typo variant="tableTitle">{t('dispatchDetail.shippingInformations.nbBoxes')}</Typo>
            <IncrementalComponent
              value={nbBoxes}
              handleDecrease={handleDecrease}
              handleIncrease={handleIncrease}
              min={1}
            />
          </div>
          <FormControl>
            <Typo variant="tableTitle">{t('dispatchDetail.shippingInformations.dispatchBy')}</Typo>
            <RadioGroup
              value={getValues('dispatch_by')}
              className="tw-gap-12"
              row
              onChange={handleDispatchByChange}
              data-testid="shipping-informations-dispatch-radio-group"
            >
              {deliveryMode !== DeliveryMode.ClicknCollect ? (
                <>
                  <FormControlLabel
                    data-testid="shipping-informations-dispatch-radio-other"
                    value={DispatchBy.OTHER}
                    control={<Radio />}
                    label={t('dispatchDetail.shippingInformations.dispatchBy', {
                      context: 'other',
                    })}
                  />
                  <FormControlLabel
                    data-testid="shipping-informations-dispatch-radio-dhl"
                    value={DispatchBy.DHL}
                    control={<Radio />}
                    label={<img src={DhlIcon} alt="dhl-icon" />}
                  />
                  <FormControlLabel
                    data-testid="shipping-informations-dispatch-radio-fedex"
                    value={DispatchBy.FEDEX}
                    control={<Radio />}
                    label={<img src={FedexIcon} alt="fedex-icon" />}
                  />
                </>
              ) : (
                <FormControlLabel
                  data-testid="shipping-informations-dispatch-radio-clickandcollect"
                  value={DispatchBy.CLICK_AND_COLLECT}
                  control={<Radio checked />}
                  label="Click & Collect"
                />
              )}
            </RadioGroup>
          </FormControl>
        </div>
      </Grid2>
      {getValues('dispatch_by') === DispatchBy.DHL &&
      deliveryMode !== DeliveryMode.ClicknCollect ? (
        <Grid2 size={{ xs: 4 }}>
          <FormTextField
            name="account_number"
            label="Account number *"
            inputDataTestId="shipping-informations-account-number-textfield"
            onBlur={() => trigger('account_number')}
            InputProps={
              isReturn
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          arrow
                          placement="top-start"
                          title={t('makeReturn.returnInformations.dhlInfo')}
                          variant="light"
                        >
                          <InfoIcon className="tw-ml-2" fontSize="small" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }
                : null
            }
          />
        </Grid2>
      ) : null}
      {deliveryMode !== DeliveryMode.ClicknCollect ? (
        <>
          <Grid2 size={{ xs: 4 }}>
            <FormTextField
              name="air_way_bill"
              label="Air way bill *"
              inputDataTestId="shipping-informations-air-way-bill-textfield"
              onBlur={() => trigger('air_way_bill')}
            />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <FormTextField
              name="incoterms"
              label="Incoterms *"
              inputDataTestId="shipping-informations-incoterms-textfield"
              onBlur={() => trigger('incoterms')}
            />
          </Grid2>
        </>
      ) : null}
    </Grid2>
  );
}
