import {
  InformationBox,
  PersonalInformations,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import { FormControlLabel, Grid2, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PickupAddress } from '../../../interfaces/address';
import { ManageBookingGrid, SetReturnInformations } from '../../../interfaces/veg';
import { $darkGreen, $darkRed, $primaryNavy } from '../../../styles/colors';
import { Currency, DEFAULT_PROFORMA_VALUE, ONLY_NUMERIC, REQUIRED_FIELD } from '../../../utils';
import { getDesignationProduct } from '../../../utils/functions';
import { roundUp } from '../../../utils/functions/numbers';
import { GridStatus } from '../../data-grid/utils';
import { FormTextField } from '../../shared';
import { getError } from '../../shared/form-components/utils';
import { DialogInspect } from '../make-return/DialogInspect';
import { getProformaValueKey } from '../utils';

export interface ShippingOrderInformationsProps {
  bookings: ManageBookingGrid[];
  sets: SetReturnInformations[];
  isReturn?: boolean;
  deliveryAddress?: PickupAddress;
  handleConfirmInspectDialog?: Function;
}

export function ShippingOrderInformations({
  bookings,
  sets,
  isReturn,
  deliveryAddress,
  handleConfirmInspectDialog,
}: ShippingOrderInformationsProps) {
  const { t } = useTranslation('gauge');
  const address =
    isReturn && deliveryAddress
      ? {
          street: deliveryAddress.street,
          town: deliveryAddress.town,
          state: deliveryAddress.country,
          zipCode: deliveryAddress.zip_code,
        }
      : bookings.map((booking) => ({
          street: booking.street,
          town: booking.town,
          state: booking.country,
          zipCode: booking.zip_code,
        }))[0];

  const methods = useFormContext();
  const { getValues, setError, clearErrors, control, formState, setValue, trigger } = methods;
  const { replace } = useFieldArray({
    control: control,
    name: 'sets',
  });

  /* istanbul ignore next */
  React.useEffect(() => {
    if (sets && !isReturn) {
      sets.forEach((_, idx) => {
        const name = getProformaValueKey(false, idx);
        setValue(name, `${DEFAULT_PROFORMA_VALUE}`);
      });
      replace(
        sets.map((set, idx) => ({
          ...set,
          business_number: bookings[idx]?.business_number,
          proforma_value: `${DEFAULT_PROFORMA_VALUE}`,
        }))
      );
    }
  }, [sets]);

  const handleChangeSetInfo = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    idx: number,
    business_number: string
  ) => {
    const formSets = getValues('sets')?.length > 0 ? getValues('sets') : sets;
    const value = e.target.value;
    const errorKey = getProformaValueKey(isReturn, idx);
    replace(
      formSets.map((set, index) => {
        if (index === idx) {
          return { ...set, business_number, proforma_value: value };
        }
        return set;
      })
    );
    if (value === '') {
      setError(errorKey, { message: REQUIRED_FIELD });
    } else if (isNaN(parseFloat(value))) {
      setError(errorKey, { message: ONLY_NUMERIC });
    } else {
      clearErrors(errorKey);
    }
  };

  const getSetColor = (set: SetReturnInformations) => {
    if (isReturn) {
      if (set?.grid_status === GridStatus.OK) {
        return $darkGreen;
      }
      return $darkRed;
    }
    return $primaryNavy;
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('currency', e.target.value);
    trigger('currency');
  };

  return (
    <Grid2 container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
      <Grid2 size={{ xs: 3 }}>
        <PersonalInformations
          isAddress
          informations={{ address }}
          addressTitle={t('dispatchDetail.orderInformations.address')}
        />
      </Grid2>
      {!isReturn && (
        <Grid2 size={{ xs: 12 }}>
          <div className="tw-flex tw-gap-2">
            <Typo className="tw-flex tw-items-center" variant="tableTitle">
              {t('dispatchDetail.shippingInformations.currency')}
            </Typo>
            <RadioGroup
              value={getValues('currency')}
              className="tw-gap-4"
              row
              onChange={handleCurrencyChange}
              data-testid="shipping-informations-currency-radio-group"
            >
              <FormControlLabel
                data-testid="shipping-informations-dispatch-radio-euro"
                value={Currency.EUR}
                control={<Radio />}
                label={
                  <Typo variant="sectionTitle">
                    {t('dispatchDetail.shippingInformations.euro')}
                  </Typo>
                }
              />
              <FormControlLabel
                data-testid="shipping-informations-dispatch-radio-dollar"
                value={Currency.USD}
                control={<Radio />}
                label={
                  <Typo variant="sectionTitle">
                    {t('dispatchDetail.shippingInformations.dollar')}
                  </Typo>
                }
              />
            </RadioGroup>
          </div>
        </Grid2>
      )}
      {bookings.map((booking, idx) => {
        const set = sets?.find((elem) => elem.set_id === booking.set_id);
        const name = getProformaValueKey(isReturn, idx);
        const error = getError(formState.errors, name);
        return (
          <React.Fragment key={booking.booking_id}>
            {bookings.length > 1 ? (
              <Grid2 size={{ xs: 12 }}>
                <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-gap-5">
                  <Typo
                    className="tw-whitespace-nowrap"
                    variant="body1"
                    fontWeight={500}
                    color={$primaryNavy}
                  >
                    {t('dispatchDetail.shippingInformations.setNb', { index: idx + 1 })}
                  </Typo>
                  <div className="tw-h-0.5 tw-w-full tw-bg-gray-200" />
                </div>
              </Grid2>
            ) : null}
            <Grid2 size={{ xs: 12 }}>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <InformationBox
                  color={getSetColor(getValues(`sets.${idx}`))}
                  data={[
                    {
                      title: t('dispatchDetail.orderInformations.infoBox.purchaseOrder'),
                      name: booking.purchase_order,
                    },
                    {
                      title: t('dispatchDetail.orderInformations.infoBox.businessNumber'),
                      name: booking.business_number,
                    },
                    {
                      title: t('dispatchDetail.shippingInformations.infoBox.designation'),
                      name: t('dispatchDetail.shippingInformations.infoBox.value', {
                        setId: set?.set_id,
                        designation: getDesignationProduct({
                          connection: set?.connection,
                          od_inch: set?.od_inch,
                          min_weight: set?.min_weight,
                          max_weight: set?.max_weight,
                          application: set?.application,
                          end: set?.end,
                          option: set?.option,
                        }),
                      }),
                    },
                    {
                      title: t('dispatchDetail.shippingInformations.infoBox.gaugeWeight'),
                      name:
                        typeof set?.gauge_weight === 'number' ? roundUp(set.gauge_weight, 2) : '-',
                    },
                    {
                      title: t('dispatchDetail.shippingInformations.infoBox.caseWeight'),
                      name:
                        typeof set?.case_weight === 'number' ? roundUp(set.case_weight, 2) : '-',
                    },
                    {
                      title: t('dispatchDetail.orderInformations.commentsTitle'),
                      name: booking.comments?.length > 0 ? booking.comments : '-',
                    },
                  ]}
                  justifyContentChildren="space-between"
                >
                  {isReturn && (
                    <div className="tw-flex tw-items-center">
                      <DialogInspect handleConfirm={handleConfirmInspectDialog} setNb={idx} />
                    </div>
                  )}
                </InformationBox>
                <FormTextField
                  {...{
                    InputLabelProps: {
                      shrink: !!getValues(name),
                    },
                  }}
                  sx={{ width: 300 }}
                  variant="standard"
                  name={name}
                  inputProps={{ 'data-testid': `order-informations-${name}-data-testid` }}
                  label="Pro-Forma value *"
                  onBlur={(
                    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
                  ) => handleChangeSetInfo(event, idx, booking.business_number)}
                  error={!!error}
                  helperText={error ? error.message : ''}
                />
              </div>
            </Grid2>
          </React.Fragment>
        );
      })}
    </Grid2>
  );
}
