import { TypeOf, number, object, string } from 'zod';

import { POSITIVE_NUMBER, REQUIRED_FIELD } from '../../utils';

export const creationFormSchema = object({
  designation: string().min(1, REQUIRED_FIELD),
  width: number().positive(POSITIVE_NUMBER),
  length: number().positive(POSITIVE_NUMBER),
  height: number().positive(POSITIVE_NUMBER),
  weight: number().positive(POSITIVE_NUMBER),
  price: number().positive(POSITIVE_NUMBER),
  case_status: string().min(0, REQUIRED_FIELD),
});

export const defaultValues: FormSchemaType = {
  designation: '',
  height: null,
  width: null,
  length: null,
  weight: null,
  price: null,
  case_status: 'active',
};

// infer schema to generate typescript type
export type FormSchemaType = TypeOf<typeof creationFormSchema>;

const updateCaseForm = object({
  creation_date: string().optional().nullable(),
  modification_date: string().optional().nullable(),
  created_by: string().optional().nullable(),
  modified_by: string().optional().nullable(),
});

export const updateFormSchema = creationFormSchema.merge(updateCaseForm);
