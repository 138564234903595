import { AxiosResponse } from 'axios';

import {
  LicenseeUsersParams,
  LicenseeUsersRolesParams,
  MyUsersRoles,
  MyUsersRolesParams,
  UpdateUserInfo,
  UpdateUserRole,
  User,
  UserListingParams,
  UserMyVamRole,
  UserResult,
  UsersByType,
  UsersMyVam,
} from '../../interfaces/user';
import { userEndpoints } from '../../utils/constants/endpoints/user-endpoints';
import { UserStatus } from '../../utils/constants/user';
import { userHttp, userHttpWithSpinner } from '../axios-services/http-user';

/**
 * get user by user_id
 * @path user_id number
 * @returns Promise User
 */
export function getUser(user_id: number): Promise<AxiosResponse<User, any>> {
  return userHttp.get<User>(userEndpoints.users_by_id(user_id));
}

/**
 * loadUserInfos
 * @returns Promise User
 */
export async function loadUserInfos(accessToken: string): Promise<AxiosResponse<UsersMyVam, any>> {
  return userHttp.get<UsersMyVam>('/me', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

/**
 * get users by email key word
 * @params query string
 * @returns Promise Users
 */
export function getUsersSearchByEmail(query: string): Promise<AxiosResponse<User[], any>> {
  return userHttp.get<User[]>(userEndpoints.users_search_by_email(query));
}

/**
 * get users by status
 * @params body request with status and licensee number
 * @returns Promise UserResult
 */
export function getUsersByStatus(
  bodyRequest: UserListingParams
): Promise<AxiosResponse<UserResult, any>> {
  return userHttpWithSpinner.post<UserResult>(userEndpoints.users_listing, bodyRequest);
}

/**
 * get my users by status
 * @params body request with status and licensee_number and user_id
 * @returns Promise UserResult
 */
export function getLicenseeUsersByStatus(
  bodyRequest: LicenseeUsersParams
): Promise<AxiosResponse<UserResult, any>> {
  return userHttpWithSpinner.post<UserResult>(userEndpoints.licensee_users, bodyRequest);
}

/**
 * Patch modify user status
 * @params user_id in path query
 * @params target_status in body request
 * @returns Promise number
 */
export function updateUserStatus(
  user_id: number,
  target_status: UserStatus
): Promise<AxiosResponse<number, any>> {
  return userHttpWithSpinner.patch<number>(userEndpoints.change_user_status(user_id), {
    target_status,
  });
}

/**
 * Update last activity date for user connected
 * @params user_id number
 * @returns Promise int
 */
export function updateLastActivityDate(user_id: number): Promise<AxiosResponse<unknown, any>> {
  return userHttp.patch<unknown>(userEndpoints.user_activity(user_id));
}

/**
 * Get my users list with roles
 * @params data MyUsersRolesParams
 * @returns Promise MyUsersRoles
 */
export function getUsersWithRoles(
  data: MyUsersRolesParams
): Promise<AxiosResponse<MyUsersRoles, any>> {
  return userHttp.post<MyUsersRoles>(userEndpoints.user_roles, data);
}

/**
 * Update user role
 * @params data UpdateUserRole
 * @returns Promise number
 */
export function updateUserRole(data: UpdateUserRole): Promise<AxiosResponse<number, any>> {
  return userHttp.post<number>(userEndpoints.update_user_roles, data);
}

/**
 * Get all auth0 roles with description
 * @returns Promise UserMyVamRole[]
 */
export function getAllAuth0Roles(): Promise<AxiosResponse<UserMyVamRole[], any>> {
  return userHttp.get<UserMyVamRole[]>(userEndpoints.roles);
}

/**
 * Update user informations
 * @params userInfo UpdateUserInfo
 * @params userId number
 * @returns Promise number
 */
export function updateUserInformations(
  userId: number,
  userInfo: UpdateUserInfo
): Promise<AxiosResponse<number, any>> {
  return userHttp.put<number>(userEndpoints.users_by_id(userId), userInfo);
}

/**
 * Get users by licensee type
 * @returns Promise UserMyVamRole[]
 */
export function getUsersByLicenseeType(
  licenseeType: string
): Promise<AxiosResponse<UsersByType[], any>> {
  return userHttp.get<UsersByType[]>(userEndpoints.users_by_type(licenseeType));
}

/**
 * Get royalty rate from licensee
 * @returns Promise number
 */
export function getLicenseeRoyaltyRate(
  licenseeNumber: number
): Promise<AxiosResponse<number, any>> {
  return userHttp.get<number>(userEndpoints.licensee_royalty_rate(licenseeNumber));
}

/**
 * for internal vam, manage-users:all we can get a users with roles
 * @params data MyUsersRolesParams
 * @returns Promise MyUsersRoles
 */
export function getUsersRightsByLicenseeNumber(
  licensee_number: number,
  data: LicenseeUsersRolesParams
): Promise<AxiosResponse<MyUsersRoles, any>> {
  return userHttp.post<MyUsersRoles>(userEndpoints.licensees_users_roles(licensee_number), data);
}
