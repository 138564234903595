import { AxiosResponse } from 'axios';

import {
  ConfirmReturnShipmentInformations,
  SaveConfirmReturnShipment,
  ShipmentDetailResult,
  ShipmentsByStatusCount,
  TrackingStatus,
  VegDispatchApi,
  VegDispatchClickAndCollectApi,
  VegReturnApi,
} from '../../interfaces/shipping';
import { SetReturnInformations } from '../../interfaces/veg';
import { BookingStatus, ShippingSource } from '../../utils';
import { endpoints } from '../../utils/constants/endpoints/veg-endpoints';
import { vegHttpWithSpinner } from '../axios-services/http-veg';

/**
 * post save new dispatch
 * @param data VegDispatchApi
 * @returns Promise id number
 */
export function saveDispatch(
  data: VegDispatchApi | VegDispatchClickAndCollectApi
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.dispatch, data);
}

/**
 * get shipment detail from manage dispatch
 * @param shipment_id number
 * @returns Promise ShipmentDetailResult[]
 */
export function getDetailShipment(
  shipment_id: number
): Promise<AxiosResponse<ShipmentDetailResult[], any>> {
  return vegHttpWithSpinner.get<ShipmentDetailResult[]>(endpoints.shipments_by_id(shipment_id));
}

/**
 * Generate all documents needed after a dispatch.
 * @param booking_ids number[]
 * @returns Promise blob
 */
export function exportDispatchAllDocuments(
  booking_ids: number[]
): Promise<AxiosResponse<Blob, any>> {
  return vegHttpWithSpinner.post<Blob>(endpoints.export_dispatch_documents, booking_ids, {
    responseType: 'blob',
  });
}

/**
 * Get shipments return informations
 * @param setIds number[]
 * @returns Promise SetReturnInformations[]
 */
export function getShippingReturnInformations(
  setIds: number[]
): Promise<AxiosResponse<SetReturnInformations[], any>> {
  return vegHttpWithSpinner.post<SetReturnInformations[]>(
    endpoints.shipments_return_informations,
    setIds
  );
}

/**
 * Post save new return
 * @param data VegReturnApi
 * @returns Promise id number
 */
export function saveReturn(data: VegReturnApi): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.shipment_return, data);
}

/**
 * Get an informations for confirm a return for a shipment
 * @param shipmentId number
 * @returns Promise ConfirmReturnShipmentInformations
 */
export function getConfirmReturnShipmentInformations(
  shipmentId: number
): Promise<AxiosResponse<ConfirmReturnShipmentInformations, any>> {
  return vegHttpWithSpinner.get<ConfirmReturnShipmentInformations>(
    endpoints.confirm_return_shipment_informations(shipmentId)
  );
}

/**
 * Post save confirm new return
 * @param data SaveConfirmReturnShipment
 * @returns Promise id number
 */
export function saveConfirmReturn(
  data: SaveConfirmReturnShipment
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.post<number>(endpoints.confirm_save_shipment, data);
}

/**
 * Generate packing list document.
 * @param booking_ids number[]
 * @returns Promise blob

export function exportPackingList(booking_ids: number[]): Promise<AxiosResponse<Blob, any>> {
  return vegHttpWithSpinner.post<Blob>(endpoints.export_packing_list, booking_ids, {
    responseType: 'blob',
  });
}
 */

/**
 * Get expedition status from dhl api
 * @param bookingId number
 * @returns Promise ConfirmReturnShipmentInformations
 */
export function getExpeditionStatus(
  bookingId: number
): Promise<AxiosResponse<TrackingStatus, any>> {
  return vegHttpWithSpinner.get<TrackingStatus>(endpoints.tracking_by_booking_id(bookingId));
}

/**
 * Get dhl export or import price
 * @param dhlZone number
 * @param weight number
 * @param shippingSource ShippingSource
 */
export function getDhlTransportPrice(
  dhlZone: number,
  weight: number,
  shippingSource: ShippingSource
): Promise<AxiosResponse<number, any>> {
  return vegHttpWithSpinner.get<number>(endpoints.dhl_price, {
    params: { dhl_zone: dhlZone, weight, shipping_source: shippingSource },
  });
}

/**
 * Get shipments counts by status
 * @param statuses
 * @param depotId
 * @returns Promise ShipmentsByStatusCount
 */
export function getShipmentsCountByStatus(
  statuses: BookingStatus[],
  depotId?: number | null
): Promise<AxiosResponse<ShipmentsByStatusCount, any>> {
  return vegHttpWithSpinner.post<ShipmentsByStatusCount>(endpoints.shipments_count, statuses, {
    params: { depot_id: depotId || null },
  });
}
