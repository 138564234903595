import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import { Close } from '@mui/icons-material';
import { Grid2 } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { addDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import ExtendIcon from '../../../assets/icons/icon-extend-booking.svg';
import { useAppSelector } from '../../../hooks';
import { extendReservationLtr } from '../../../services/api/veg-api';
import { selectUserId } from '../../../services/store/userSlice';
import { $primaryNavy } from '../../../styles/colors';
import { BookingStatus } from '../../../utils';
import { getDateFormat } from '../../../utils/functions';
import { CloseIconButton, FormTextField } from '../../shared';

interface DialogExtendReservationProps {
  id: number;
  endDate: string;
  ancient_po?: string;
  openExtendDialog: boolean;
  setOpenExtendDialog: Function;
  updateActualStatus: Function;
}

export function DialogExtendReservation({
  id,
  openExtendDialog,
  setOpenExtendDialog,
  endDate,
  ancient_po,
  updateActualStatus,
}: DialogExtendReservationProps) {
  const { t } = useTranslation('gauge', {
    keyPrefix: 'manageBookingDetail.extendBookingDialog',
  });
  const { enqueueSnackbar } = useSnackbar();
  const { setValue, watch } = useFormContext();
  const userId = useAppSelector(selectUserId);
  const actualEndDate = new Date(endDate);
  const newEndDate = addDays(actualEndDate, 181);
  const extendedPO = watch('extended_po');

  useEffect(() => {
    setValue('extended_po', ancient_po);
  }, [openExtendDialog]);

  /* istanbul ignore next */
  const handleExtendBooking = () => {
    extendReservationLtr({ booking_id: id, user_id: userId, extended_purchase_order: extendedPO })
      .then(() => {
        enqueueSnackbar(t('extendBookingOk'), {
          preventDuplicate: true,
          variant: 'success',
        });
        setValue('end_date', newEndDate);
        updateActualStatus(BookingStatus.Delivered);
        handleClose();
      })
      .catch(function (error) {
        console.error(error);
        enqueueSnackbar(t('extendBookingFailed'), {
          preventDuplicate: true,
          variant: 'error',
        });
      });
  };

  /* istanbul ignore next */
  const handleClose = () => {
    setOpenExtendDialog(false);
  };

  return (
    <Dialog open={openExtendDialog} onClose={handleClose} fullWidth={true} maxWidth="md">
      <CloseIconButton data-testid="dialog-close-button" onClick={handleClose}>
        <Close />
      </CloseIconButton>

      <DialogTitle>
        <div className="tw-flex tw-flex-col tw-text-center">
          <div className="tw-pt-2">
            <img src={ExtendIcon} alt="support-switch-status-icon" />
          </div>
          <div>
            <Typo variant="body1" color={$primaryNavy} fontWeight={500}>
              {t('title')}
            </Typo>
            <Typo variant="subtitle4">{t('subTitle')}</Typo>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
        <Grid2
          container
          rowSpacing={3}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          className="tw-px-16"
        >
          <Grid2 size={{ xs: 12 }}>
            <Typo variant="body1">
              <Trans components={{ bold: <strong /> }}>
                {t('descriptionActualEndDate', { end_date: getDateFormat(actualEndDate, false) })}
              </Trans>
            </Typo>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typo variant="body1">
              <Trans components={{ bold: <strong /> }}>
                {t('descriptionNewEndDate', { new_end_date: getDateFormat(newEndDate, false) })}
              </Trans>
            </Typo>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Typo variant="body1">
              <Trans components={{ bold: <strong /> }}>{t('descriptionInvoice')}</Trans>
            </Typo>
          </Grid2>
          <div className="tw-flex tw-w-full tw-justify-center">
            <Grid2 size={{ xs: 4 }}>
              <FormTextField
                name="extended_po"
                label={t('po')}
                inputDataTestId="extended-po-textfield"
                size="small"
              />
            </Grid2>
          </div>
        </Grid2>
      </DialogContent>

      <DialogActions className="!tw-justify-center">
        <Button
          data-testid="confirm-change-status-btn-submit"
          variant="cta"
          type="submit"
          onClick={handleExtendBooking}
          disabled={!extendedPO?.length}
        >
          {t('validate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
