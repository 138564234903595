import {
  IncrementalComponent,
  SectionTitle,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import { Grid2 } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks';
import { DistinctCGLabelValue } from '../../interfaces/distinct-product-label-value';
import { handleFilterChange, partTypes } from '../../pages/royalties/utils';
import { getDistinctGradeApp } from '../../services/api/documentation-api';
import { selectUser } from '../../services/store/userSlice';
import { isoCountriesLabelValue } from '../../utils/constants/countries/countries_code_alpha_2';
import { JobType } from '../../utils/constants/royalties';
import { arrayToLabelValuesByRef, toLabelValues, toOptionsFilters } from '../../utils/functions';
import { ContainerCard, FormDatePicker, FormSelect, FormTextField } from '../shared';

interface JobInformationsProps {
  numberParts: number;
  setNumberParts: Function;
}

export function JobInformations({ numberParts, setNumberParts }: JobInformationsProps) {
  const { t } = useTranslation('royalties', { keyPrefix: 'createJob.jobInformations' });
  const [waiverRequestRequired, setWaiverRequestRequired] = React.useState<boolean>(false);
  const [distinctGradeLabelValue, setDistinctGrade] = React.useState({
    grade_name: [],
    grade_family: [],
    yield_strength: [],
  } as DistinctCGLabelValue);
  const [filters, setFilters] = React.useState([]);

  const user = useAppSelector(selectUser);

  const methods = useFormContext();
  const { watch, setError, clearErrors, setValue, trigger } = methods;

  const jobType = watch('job_type');
  const typeOfPart = watch('type_of_part');
  const endUserCountry = watch('country_of_delivery');
  const finalDestinationCountry = watch('final_destination');
  const waiverRequest = watch('waiver_request');
  const waiverErrorMessage = t('waiverErrorMessage');
  const handleChangeNumberParts = (newValue: number) => {
    setNumberParts(newValue);
    setValue('number_of_parts', newValue);
    trigger('number_of_parts');
  };

  React.useEffect(() => {
    let waiverRequired = false;

    // Apply rules to determine if waiver_request is required
    if (
      (user.licenseeType === 'LICENSEE' && jobType === JobType.NEW_PART) ||
      (!user.allowExport &&
        endUserCountry?.length &&
        finalDestinationCountry?.length &&
        endUserCountry !== finalDestinationCountry) ||
      (typeOfPart === 'Coupling' && user.licenseeType === 'LICENSEE')
    ) {
      waiverRequired = true;
    }

    // Set or clear errors based on waiver requirement
    if (waiverRequired) {
      if (!waiverRequest) {
        setError('waiver_request', { type: 'manual', message: waiverErrorMessage });
      } else {
        clearErrors('waiver_request');
      }
    } else {
      clearErrors('waiver_request');
    }
    setWaiverRequestRequired(waiverRequired);
  }, [jobType, typeOfPart, endUserCountry, finalDestinationCountry, waiverRequest]);

  React.useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = async () => {
    try {
      const { data } = await getDistinctGradeApp(filters);
      const labelValueOptions = toOptionsFilters(data as any);
      setDistinctGrade(labelValueOptions);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      containerCardClass="bg-color-white"
      dataTestId="job-informations-container"
      avatarHeaderNode={<SectionTitle title={t('title')} description={t('description')} />}
    >
      <Grid2 container columnSpacing={{ xs: 2, sm: 3, md: 4 }} rowGap={1}>
        <Grid2 size={{ xs: 2 }}>
          <FormDatePicker
            id="date-select"
            variant="standard"
            name="date"
            label={t('form.date')}
            dataTestId="date-test-id"
            disabled
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormSelect
            id="type-of-part-select"
            data-testid="type-of-part-select"
            variant="standard"
            name="type_of_part"
            label={t('form.typeOfPart')}
            options={arrayToLabelValuesByRef(partTypes, 'type', 'type')}
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormSelect
            id="job-type-select"
            data-testid="job-type-select"
            variant="standard"
            name="job_type"
            label={t('form.jobType')}
            options={toLabelValues(JobType)}
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormTextField
            id="customer"
            name="customer"
            label={t('form.customer')}
            inputDataTestId="customer-textfield"
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormSelect
            id="country-of-delivery-select"
            data-testid="country-of-delivery-select"
            variant="standard"
            name="country_of_delivery"
            label={t('form.deliveryCountry')}
            options={isoCountriesLabelValue}
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormTextField
            id="end_user"
            name="end_user"
            label={t('form.endUser')}
            inputDataTestId="end-user-textfield"
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormSelect
            id="final-destination-select"
            data-testid="final-destination-select"
            variant="standard"
            name="final_destination"
            label={t('form.finalDestination')}
            options={isoCountriesLabelValue}
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormTextField
            id="work_order"
            name="work_order"
            label={t('form.workOrder')}
            inputDataTestId="work-order-textfield"
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormSelect
            id="grade-family-select"
            data-testid="grade-family-select"
            variant="standard"
            name="grade_family"
            label={t('form.gradeFamily')}
            options={distinctGradeLabelValue?.grade_family}
            handleChange={(controlValue: any, name: string) =>
              handleFilterChange(filters, setFilters, controlValue, name)
            }
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormSelect
            id="yield-strength-select"
            data-testid="yield-strength-select"
            variant="standard"
            name="yield_strength"
            label={t('form.yieldStrength')}
            options={distinctGradeLabelValue?.yield_strength}
            handleChange={(controlValue: any, name: string) =>
              handleFilterChange(filters, setFilters, controlValue, name)
            }
          />
        </Grid2>
        <Grid2 size={{ xs: 2 }}>
          <FormSelect
            id="grade-select"
            data-testid="grade-select"
            variant="standard"
            name="grade_name"
            label={t('form.gradeName')}
            options={distinctGradeLabelValue?.grade_name}
            handleChange={(controlValue: any, name: string) =>
              handleFilterChange(filters, setFilters, controlValue, name)
            }
          />
        </Grid2>
        {waiverRequestRequired && (
          <Grid2 size={{ xs: 2 }}>
            <FormTextField
              id="waiver_request"
              name="waiver_request"
              label={t('form.waiverRequest')}
              inputDataTestId="waiver-request-textfield"
            />
          </Grid2>
        )}
        <Grid2 size={{ xs: 6 }} sx={{ marginTop: 2 }}>
          <IncrementalComponent
            value={numberParts}
            min={1}
            handleIncrease={() => handleChangeNumberParts(numberParts + 1)}
            handleDecrease={() => handleChangeNumberParts(numberParts - 1)}
            handleChange={(value) => handleChangeNumberParts(value < 1 ? 1 : value)}
            inputTestId="incremental-button-textfield"
            max={9999}
          />
          <Typo variant="inputLabel" fontWeight={500}>
            {t('form.numberParts')}
          </Typo>
        </Grid2>
      </Grid2>
    </ContainerCard>
  );
}
