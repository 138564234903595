import { AxiosResponse } from 'axios';

import {
  CompanyRole,
  CompanyRoles,
  CompanyType,
  Licensee,
  LicenseeByType,
  LicenseeDocumentationQualification,
  LicenseeIdsParams,
  LicenseeParams,
  LicenseeResult,
  ModifyLicensee,
  ProQualification,
  Qualifications,
  SaveLicensee,
  UpdateCompanyRole,
} from '../../interfaces/context';
import { AxiosLicenseeDetails, LicenseeResults } from '../../interfaces/licensee';
import { contextEndpoints } from '../../utils/constants/endpoints/context-endpoints';
import { contextHttp, contextHttpWithSpinner } from '../axios-services/http-context';

export function getLicenseeByStatus(
  bodyRequest: LicenseeParams
): Promise<AxiosResponse<LicenseeResult, any>> {
  return contextHttpWithSpinner.post<LicenseeResult>(contextEndpoints.licensee_status, bodyRequest);
}

/**
 * Get unique stamp
 * @param stamp string
 * @returns Promise string
 */
export function getLicenseeStamp(stamp?: string): Promise<AxiosResponse<string, any>> {
  return contextHttp.get<string>(contextEndpoints.licensees_stamp, {
    params: { stamp },
  });
}

/**
 * Check if ids are uniques
 * @param params LicenseeIdsParams
 * @returns Promise string
 */
export function checkIsUnique(params: LicenseeIdsParams): Promise<AxiosResponse<string, any>> {
  return contextHttp.get<string>(contextEndpoints.licensees_check_unique_ids, {
    params,
  });
}

/**
 * Getting qualifications object
 * @returns Promise Qualifications
 */
export function fetchQualifications(): Promise<AxiosResponse<Qualifications, any>> {
  return contextHttp.get<Qualifications>(contextEndpoints.qualifications);
}

/**
 * Post save new licensee
 * @param data SaveLicensee
 * @returns Promise id number
 */
export function saveLicensee(data: SaveLicensee): Promise<AxiosResponse<number, any>> {
  return contextHttpWithSpinner.post<number>(contextEndpoints.licensees, data);
}

/**
 * Get licensees by licensee number
 * @params licenseeNumber string
 * @returns Promise Licensee
 */
export function getLicensees(licenseeNumber: string): Promise<AxiosResponse<Licensee[], any>> {
  return contextHttp.get<Licensee[]>(contextEndpoints.licensees_search_by_number(licenseeNumber));
}

/**
 * Get Detail of licensee by licenseeNumber
 * @param licenseeNumber number
 * @returns Promise LicenseeResults
 */
export function getLicenseeDetails(licenseeNumber: number): Promise<AxiosLicenseeDetails> {
  return contextHttpWithSpinner.get<LicenseeResults>(
    contextEndpoints.licensee_by_number(licenseeNumber)
  );
}

/**
 * Put modify licensee
 * @param licenseeNumber number
 * @param data IUpdateLicensee
 * @returns Promise id number
 */
export function modifyLicensee(
  licenseeNumber: number,
  data: ModifyLicensee
): Promise<AxiosResponse<number, any>> {
  return contextHttpWithSpinner.put<number>(
    contextEndpoints.licensee_by_number(licenseeNumber),
    data
  );
}

/**
 * Get licensee product qualifications
 * @param licenseeNumber number
 * @returns Promise ProQualification[]
 */
export function getProductQualifications(
  licenseeNumber: number
): Promise<AxiosResponse<ProQualification[], any>> {
  return contextHttp.get<ProQualification[]>(
    contextEndpoints.licensees_product_qualifications(licenseeNumber)
  );
}

/**
 * Get licensee process qualifications
 * @param licenseeNumber number
 * @returns Promise ProQualification[]
 */
export function getProcessQualifications(
  licenseeNumber: number
): Promise<AxiosResponse<ProQualification[], any>> {
  return contextHttp.get<ProQualification[]>(
    contextEndpoints.licensees_process_qualifications(licenseeNumber)
  );
}

/**
 * Get licensee process qualifications
 * @param licenseeNumber number
 * @returns Promise SaveDocumentationQualification[]
 */
export function getDocumentationQualifications(
  licenseeNumber: number
): Promise<AxiosResponse<LicenseeDocumentationQualification[], any>> {
  return contextHttp.get<LicenseeDocumentationQualification[]>(
    contextEndpoints.licensees_documentation_qualifications(licenseeNumber)
  );
}

/**
 * Get licensee by type
 * @param types string[]
 * @returns Promise LicenseeByType[]
 */
export function getLicenseeByType(types: string[]): Promise<AxiosResponse<LicenseeByType[], any>> {
  return contextHttpWithSpinner.post<LicenseeByType[]>(contextEndpoints.licensees_by_type, types);
}

/**
 * Get company types
 * @return Promise CompanyType[]
 */
export function getCompanyTypes(): Promise<AxiosResponse<CompanyType[], any>> {
  return contextHttpWithSpinner.get<CompanyType[]>(contextEndpoints.companies_type);
}

/**
 * Get company roles by type
 * @params type string
 * @return Promise CompanyRole[]
 */
export function getCompanyRolesByType(type: string): Promise<AxiosResponse<CompanyRole[], any>> {
  return contextHttpWithSpinner.get<CompanyRole[]>(contextEndpoints.companies_roles_by_type(type));
}

/**
 * Get company roles for roles management grid
 * @return Promise CompanyRoles
 */
export function getCompaniesRoles(): Promise<AxiosResponse<CompanyRoles, any>> {
  return contextHttpWithSpinner.get<CompanyRoles>(contextEndpoints.companies_roles);
}

/**
 * Update company role
 * @param params UpdateCompanyRole
 * @return Promise number | null
 */
export function updateCompanyRole(
  params: UpdateCompanyRole
): Promise<AxiosResponse<number | null, any>> {
  return contextHttpWithSpinner.post<number | null>(contextEndpoints.companies_roles, params);
}

/**
 * Create company type
 * @params companyType CompanyType
 * @returns Promise number
 */
export function createCompanyType(companyType: CompanyType): Promise<AxiosResponse<number, any>> {
  return contextHttpWithSpinner.post<number>(contextEndpoints.companies, companyType);
}

/**
 * Get company roles by licensee number
 * @params type string
 * @return Promise CompanyRole[]
 */
export function getCompanyRolesByLicensee(
  licensee_number: number
): Promise<AxiosResponse<CompanyRole[], any>> {
  return contextHttpWithSpinner.get<CompanyRole[]>(
    contextEndpoints.manage_companies_roles(licensee_number)
  );
}
