import { SectionTitle } from '@digital-at-vallourec/steel-design-system-react';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { numberRegexWithTwoDigit } from '../../utils';
import { arrayToLabelValues } from '../../utils/functions';
import { ContainerCard, FormSelect, FormTextField } from '../shared';

export function CaseInformations() {
  const { t } = useTranslation('gauge_case', { keyPrefix: 'createCase.caseInformation' });

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={<SectionTitle title={t('title')} description={t('description')} />}
    >
      <Grid container spacing={8} lineHeight={4}>
        <Grid item xs={4}>
          <FormTextField
            id="designation"
            name="designation"
            label={t('form.designation')}
            inputDataTestId="case-designation-textfield"
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            id="width-case"
            name="width"
            label={t('form.width')}
            inputDataTestId="width-case-textfield"
            type="number"
            patternValue={numberRegexWithTwoDigit}
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            id="length-case"
            name="length"
            label={t('form.length')}
            type="number"
            inputDataTestId="length-case-textfield"
          />
        </Grid>
      </Grid>
      <Grid container spacing={8} lineHeight={4}>
        <Grid item xs={4}>
          <FormTextField
            id="height-case"
            name="height"
            label={t('form.height')}
            type="number"
            inputDataTestId="height-case-textfield"
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            id="weight-case"
            name="weight"
            label={t('form.weight')}
            type="number"
            inputDataTestId="weight-case-textfield"
          />
        </Grid>
        <Grid item xs={4}>
          <FormTextField
            id="price-case"
            type="number"
            name="price"
            label={t('form.price')}
            inputDataTestId="price-case-textfield"
          />
        </Grid>
      </Grid>
      <Grid container spacing={8} lineHeight={4}>
        <Grid item xs={4}>
          <FormSelect
            id="case_status"
            name="case_status"
            variant="standard"
            label={t('form.case_status')}
            options={arrayToLabelValues(['active', 'draft'], 'status.')}
            removeEmptyValue={true}
            data-testid="case-status-select"
            nsTranslate="gauge_case"
          />
        </Grid>
      </Grid>
    </ContainerCard>
  );
}
