import { TypeOf, date, number, object, string } from 'zod';

export const formSchema = object({
  gauge_location_id: number().optional().array(),
  period_end: date().optional().nullable(),
  business_number: string().optional(),
  set_id: string().nullable().optional(),
  rental_type: string().optional().array(),
  licensee_number: number().nullable().optional(),
});

export const defaultValues: FormSchemaType = {
  gauge_location_id: [],
  period_end: null,
  business_number: '',
  set_id: '',
  rental_type: [],
  licensee_number: null,
};

// infer schema to generate typescript type
export type FormSchemaType = TypeOf<typeof formSchema>;
