import { CircleStatus } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Grid2, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { ContainerOutlet } from '../../components/layout/ContainerOutlet/ContainerOutlet';
import { BillingDetails } from '../../components/royalties/BillingDetails';
import {
  FormSchemaType,
  newJobDefaultValues,
  newJobSchema,
} from '../../components/royalties/CreateJobForm';
import { JobDetails } from '../../components/royalties/JobDetails';
import { JobInformations } from '../../components/royalties/JobInformations';
import {
  CtaLoadingButton,
  DialogSaveJob,
  MenuOptionsProps,
  VamMoreOptions,
  VamPageTitle,
} from '../../components/shared';
import { useAppSelector } from '../../hooks';
import { SaveRoyaltyJob } from '../../interfaces/royalty';
import { saveRoyaltyJob } from '../../services/api/royalty-api';
import {
  selectLicenseeNumber,
  selectPricingPlanUuid,
  selectUser,
} from '../../services/store/userSlice';
import { $lightBlue } from '../../styles/colors';
import { JOB_STATUS_COLORS, JobStatus } from '../../utils/constants/royalties';
import { getJobTitle } from '../../utils/functions';

export default function CreateJob() {
  const { t } = useTranslation('royalties', { keyPrefix: 'createJob' });
  const { t: tCommon } = useTranslation('common');
  const methods = useForm<FormSchemaType>({
    defaultValues: newJobDefaultValues,
    resolver: zodResolver(newJobSchema),
  });

  const [status, setStatus] = React.useState<JobStatus>(JobStatus.COMPLETE);
  const [jobId, setJobId] = React.useState<number>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [numberParts, setNumberParts] = React.useState<number>(1);

  const { handleSubmit, reset, clearErrors } = methods;

  const { firstName, lastName } = useAppSelector(selectUser);
  const licenseeNumber = useAppSelector(selectLicenseeNumber);
  const pricingPlanUuid = useAppSelector(selectPricingPlanUuid);

  /* istanbul ignore next */
  const options: MenuOptionsProps[] = [
    {
      label: t('status.COMPLETE'),
      name: JobStatus.COMPLETE,
      handleItemClick: () => {
        setStatus(JobStatus.COMPLETE);
      },
    },
    {
      label: t('status.OPEN'),
      name: JobStatus.OPEN,
      handleItemClick: () => {
        setStatus(JobStatus.OPEN);
      },
    },
  ];

  /* istanbul ignore next */
  const handleSaveRoyaltyJob: SubmitHandler<FormSchemaType> = (data) => {
    const newRoyaltyJob: SaveRoyaltyJob = {
      part_type: data.type_of_part,
      job_type: data.job_type,
      delivery_country: data.country_of_delivery,
      end_user: data.end_user,
      final_destination: data.final_destination,
      work_order: data.work_order,
      grade_family: data.grade_family,
      grade_name: data.grade_name,
      yield_strength: data.yield_strength,
      waiver_request: data.waiver_request,
      parts_number: data.number_of_parts,
      sides: data.sides,
      licensee_number: licenseeNumber,
      customer: data.customer,
      created_by: `${firstName} ${lastName}`,
      total_price: data.total_price,
      status: status,
      pricing_plan_uuid: pricingPlanUuid,
    };
    saveRoyaltyJob(newRoyaltyJob)
      .then(({ data }) => {
        if (data === -1) {
          Swal.fire({
            icon: 'error',
            title: t('alert.title'),
            text: t('alert.text'),
          });
        } else {
          setJobId(data);
          setOpen(true);
        }
      })
      .catch(function (error) {
        enqueueSnackbar(tCommon('notification.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.log(error);
      });
  };

  const handleCloseDialog = () => {
    setOpen(false);
    reset(newJobDefaultValues);
    clearErrors();
    setStatus(JobStatus.COMPLETE);
    setJobId(null);
    setNumberParts(1);
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <DialogSaveJob
          jobId={jobId}
          isOpen={open}
          setIsOpen={setOpen}
          urlToReturn="./" // TODO: change this to the correct url (management)
          handleCloseDialog={handleCloseDialog}
        />
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('subtitle')}
              title={getJobTitle()}
              breadcrumbRoutes={[
                {
                  breadcrumb: 'Create a Job',
                  path: '#',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <CircleStatus label={t(`status.${status}`)} color={JOB_STATUS_COLORS[status]} />
            <VamMoreOptions
              data-testid="vam-menu-detail-actions"
              options={options.filter((item) => item.name !== status)}
            />
            <CtaLoadingButton
              buttonText={t('submitBtn')}
              type="submit"
              variant="cta"
              data-testid="submit-create-job-btn"
              onClick={handleSubmit(handleSaveRoyaltyJob)}
            />
          </Stack>
        </Stack>
        <Grid2
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: $lightBlue,
          }}
        >
          <Grid2 size={{ xs: 12 }}>
            <JobInformations numberParts={numberParts} setNumberParts={setNumberParts} />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <JobDetails />
          </Grid2>
          <Grid2 size={{ xs: 8 }}>
            <BillingDetails />
          </Grid2>
        </Grid2>
      </FormProvider>
    </ContainerOutlet>
  );
}
