export const REQUIRED_FIELD = 'Field is required';
export const AWB_MIN = 'AWB should be 10 characters';
export const REQUIRED_DATE_FIELD = 'Please select a date';
export const ONLY_NUMERIC = 'Must contain only numeric characters';
export const ONLY_ALPHANUMERIC = 'Must contain only alphanumeric characters';
export const INVALID_NUMERIC = 'Invalid Numeric value';
export const INVALID_EMAIL = 'Invalid email address';
export const INVALID_PERCENTAGE_VALUE = 'Should be between 0 and 100';
export const INVALID_MAX_FIELD = 'Input exceeds the maximum allowed characters';
export const priceRegex = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
export const numberRegexWithTwoDigit = new RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
export const numberRegex = new RegExp(/^[0-9]*$/);
export const businessNumberListRegex = new RegExp(/^\d{4}\/\d{5}(,\d{4}\/\d{5})*$/);
export const setIdListRegex = new RegExp(/^\d+(,\d+)*$/);
export const NOT_EMPTY = 'Should have at least one item';
export const domainRegex = new RegExp(/^@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/);
export const DOMAIN_MESSAGE = 'Domain should be in the format @domain.xx';
export const POSITIVE_NUMBER = 'Should be a positive number';
export const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
