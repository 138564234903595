import { GridColDef } from '@mui/x-data-grid';

import { PricingPlanDetails, SideType } from '../../interfaces/royalty';
import { Currency } from '../../utils';
import { roundUp } from '../../utils/functions/numbers';
import { formatPriceLocaleAware } from '../../utils/functions/price';
import { GridStatus } from '../data-grid/utils';
import { columnRenderHeader } from '../data-grid/utils/functions';

/* istanbul ignore next */
export const billingCols: GridColDef[] = [
  {
    field: 'end',
    headerName: 'END',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    maxWidth: 60,
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.end || '-';
    },
  },
  {
    field: 'quantity',
    headerName: 'QTY',
    flex: 1,
    maxWidth: 60,
  },
  {
    field: 'base_price',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('BASE', 'PRICE'),
    flex: 1,
    maxWidth: 80,
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.base_price
        ? formatPriceLocaleAware(params.row.base_price, params.row.currency)
        : '-';
    },
  },
  {
    field: 'quantity_rate',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('QTY', 'RATE'),
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.quantity_rate ? `${roundUp(params.row.quantity_rate * 100, 2)}%` : '-';
    },
    flex: 1,
  },
  {
    field: 'connection_rate',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('CONNECTION', 'RATE'),
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.connection_rate ? `${roundUp(params.row.connection_rate * 100, 2)}%` : '-';
    },
    flex: 1,
  },
  {
    field: 'material_rate',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('MATERIAL', 'RATE'),
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.material_rate ? `${roundUp(params.row.material_rate * 100, 2)}%` : '-';
    },
    flex: 1,
  },
  {
    field: 'part_type_rate',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('PART TYPE', 'RATE'),
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.part_type_rate ? `${roundUp(params.row.part_type_rate * 100, 2)}%` : '-';
    },
    flex: 1,
  },
  {
    field: 'special_bevel_rate',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('BEVEL', 'RATE'),
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.special_bevel_rate
        ? `${roundUp(params.row.special_bevel_rate * 100, 2)}%`
        : '-';
    },
    flex: 1,
    maxWidth: 80,
  },
  {
    field: 'oriented_connection_rate',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('ORIENTED', 'RATE'),
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.oriented_connection_rate
        ? `${roundUp(params.row.oriented_connection_rate * 100, 2)}%`
        : '-';
    },
    flex: 1,
  },
  {
    field: 'isolated_rate',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('ISOLATED', 'RATE'),
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.isolated_rate ? `${roundUp(params.row.isolated_rate * 100, 2)}%` : '-';
    },
    flex: 1,
  },
  {
    field: 'subtotal',
    headerName: 'SUBTOTAL',
    renderCell: /* istanbul ignore next */ (params) => {
      return params.row.subtotal
        ? formatPriceLocaleAware(params.row.subtotal, params.row.currency)
        : '-';
    },
    flex: 1,
    minWidth: 100,
  },
];

export function getSideKey(side: SideType) {
  const { id, ...rest } = side;

  return JSON.stringify(rest);
}

export function groupSidesByAttributes(sides: SideType[]) {
  const grouped = {};

  for (const side of sides) {
    const key = getSideKey(side);
    if (!grouped[key]) {
      grouped[key] = { items: [], count: 0 };
    }
    grouped[key].items.push(side);
    grouped[key].count++;
  }

  return grouped;
}

/**
 * Determines if the connection requires the isolated_rate.
 *
 * A connection requires the isolated_rate if its name contains:
 * - `-N` followed by a single character (e.g., `-NR`)
 * - `-K` followed by a single character (e.g., `-KB`)
 *
 * Examples:
 * - "VAM-TOP-KB" -> true
 * - "VAM-TOP-NR" -> true
 * - "VAM-TOP" -> false
 * - "VAM-TOP-RB" -> false
 *
 * @param connectionName The name of the connection.
 * @returns true if the connection requires the isolated_rate, false otherwise.
 */
export function requiresIsolatedRate(connectionName: string): boolean {
  // Regex to match "-N" or "-K" followed by a single alphabetical character at the end of the string
  const pattern = /-[NK][A-Za-z]$/;
  return pattern.test(connectionName);
}

/**
 * Calculates all rates for a group of sides that share the same attributes
 */
export function computeRatesForGroup(
  side: SideType,
  groupQuantity: number,
  plan: PricingPlanDetails,
  gradeFamily: string,
  partType: string
) {
  // 1) quantity_rate: find the bracket that fits groupQuantity
  let quantityRate = 1.0;
  const foundQty = plan.quantity_rates.find(
    (qr) => qr.min_qty <= groupQuantity && qr.max_qty >= groupQuantity
  );
  if (foundQty) {
    quantityRate = foundQty.rate;
  }

  // 2) base_price: depends on od_inch
  let basePrice = 0;
  if (side.od_inch !== null) {
    const foundBp = plan.base_prices.find(
      (bp) => bp.min_od <= side.od_inch! && bp.max_od >= side.od_inch!
    );
    if (foundBp) {
      basePrice = foundBp.base_price;
    }
  }

  // 3) connection_rate: depends on connection
  let connectionRate = 1.0;
  if (side.connection) {
    const foundConn = plan.connection_rates.find((cr) => cr.connection === side.connection);
    if (foundConn) {
      connectionRate = foundConn.rate;
    }
  }

  // 4) material_rate: depends on 'gradeFamily' and 'end'
  //    If end=BOX and there's a box_rate, multiply material_rate by box_rate.
  let materialRate = 1.0;
  const foundMat = plan.material_rates.find((mr) => mr.grade_family === gradeFamily);
  if (foundMat) {
    materialRate = foundMat.material_rate;
    if (side.end === 'BOX' && foundMat.box_rate !== null) {
      materialRate *= foundMat.box_rate;
    }
  }

  // 5) part_type_rate: depends on 'partType'
  let partTypeRate = 1.0;
  if (partType) {
    const foundPt = plan.part_type_rates.find((pt) => pt.part_type === partType);
    if (foundPt) {
      partTypeRate = foundPt.rate;
    }
  }

  // 6) special_bevel_rate: apply plan.special_bevel_rate if side has specialBevel
  let specialBevelRate = 1.0;
  if (side.special_bevel) {
    specialBevelRate = plan.special_bevel_rate;
  }

  // 7) oriented_connection_rate: apply plan.oriented_connection_rate if side has orientedConnection
  let orientedConnectionRate = 1.0;
  if (side.oriented_connection) {
    orientedConnectionRate = plan.oriented_connection_rate;
  }

  // 8) isolated_rate: if 'requires_isolated_coef' returns true, we apply plan.isolated_rate
  let isolatedRate = 1.0;
  if (side.connection && requiresIsolatedRate(side.connection)) {
    isolatedRate = plan.isolated_rate;
  }

  return {
    base_price: basePrice,
    quantity_rate: quantityRate,
    connection_rate: connectionRate,
    material_rate: materialRate,
    part_type_rate: partTypeRate,
    special_bevel_rate: specialBevelRate,
    oriented_connection_rate: orientedConnectionRate,
    isolated_rate: isolatedRate,
  };
}

export function calculateAllRates(
  grouped: Record<string, { items: any[]; count: number }>,
  numberOfParts: number,
  plan: PricingPlanDetails | null,
  gradeFamily: string,
  partType: string
): Record<string, any> {
  if (!plan) return {};

  const results: Record<string, any> = {};

  Object.keys(grouped).forEach((key) => {
    const groupData = grouped[key];
    const firstSide = groupData.items[0];
    const groupQuantity = groupData.count * numberOfParts;

    const sideCopy = { ...firstSide };

    const rates = computeRatesForGroup(sideCopy, groupQuantity, plan, gradeFamily, partType);
    results[key] = rates;
  });

  return results;
}

export function generateRows(
  sides: SideType[],
  allRates: Record<string, any>,
  numberOfParts: number,
  planCurrency: string | undefined
): any[] {
  return sides.map((side, idx) => {
    const sideKey = getSideKey(side);
    const rates = allRates[sideKey] || {
      base_price: 0,
      quantity_rate: null,
      connection_rate: null,
      material_rate: null,
      part_type_rate: null,
      special_bevel_rate: null,
      oriented_connection_rate: null,
      isolated_rate: null,
    };

    const {
      base_price,
      quantity_rate,
      connection_rate,
      material_rate,
      part_type_rate,
      special_bevel_rate,
      oriented_connection_rate,
      isolated_rate,
    } = rates;

    const displayedQuantity = numberOfParts;

    let subtotal: number | null = null;

    if (
      base_price !== null &&
      quantity_rate !== null &&
      connection_rate !== null &&
      material_rate !== null &&
      part_type_rate !== null &&
      special_bevel_rate !== null &&
      oriented_connection_rate !== null &&
      isolated_rate !== null
    ) {
      subtotal =
        displayedQuantity *
        base_price *
        quantity_rate *
        connection_rate *
        material_rate *
        part_type_rate *
        special_bevel_rate *
        oriented_connection_rate *
        isolated_rate;
    }

    return {
      id: idx,
      end: side.end,
      connection: side.connection,
      od_inch: side.od_inch,
      weight: side.weight,
      application: side.application,
      special_bevel: side.special_bevel,
      oriented_connection: side.oriented_connection,
      quantity: displayedQuantity,
      base_price,
      quantity_rate,
      connection_rate,
      material_rate,
      part_type_rate,
      special_bevel_rate,
      oriented_connection_rate,
      isolated_rate,
      subtotal,
      currency: Currency[planCurrency ?? 'USD'],
      grid_status: GridStatus.NAVY,
    };
  });
}

export function calculateTotal(rows: any[]): number {
  return roundUp(
    rows.reduce((acc, row) => acc + (row.subtotal || 0), 0),
    2
  );
}
