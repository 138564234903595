import { TypeOf, array, number, object, string } from 'zod';

import { AWB_MIN, DeliveryMode, DispatchBy, REQUIRED_FIELD } from '../../../utils';

export const dispatchSetFormSchema = object({
  application: string().min(1, REQUIRED_FIELD),
  business_number: string().min(1, REQUIRED_FIELD),
  case_weight: number(),
  connection: string().min(1, REQUIRED_FIELD),
  end: string().min(1, REQUIRED_FIELD),
  gauge_location: string().min(1, REQUIRED_FIELD),
  gauge_weight: number(),
  max_weight: number(),
  min_weight: number(),
  od_inch: number(),
  option: string().min(1, REQUIRED_FIELD),
  proforma_value: string().min(1, REQUIRED_FIELD),
  set_id: number(),
  total_weight: number(),
});

export const dispatchFormSchema = (deliveryMode: DeliveryMode) =>
  object({
    nb_boxes: number(),
    dispatch_by: string().min(1, REQUIRED_FIELD),
    account_number: string().optional(),
    air_way_bill: string().optional(),
    incoterms:
      deliveryMode === DeliveryMode.ClicknCollect
        ? string().optional()
        : string().min(1, REQUIRED_FIELD),
    sets: array(dispatchSetFormSchema),
    comments: string().optional(),
    currency: string().min(1, REQUIRED_FIELD),
  }).superRefine((data, ctx) => {
    if (deliveryMode === DeliveryMode.ClicknCollect) return;

    if (data.dispatch_by === DispatchBy.DHL) {
      if (!data.air_way_bill || data.air_way_bill.length !== 10) {
        ctx.addIssue({
          path: ['air_way_bill'],
          code: 'custom',
          message: AWB_MIN,
        });
      }
    } else {
      if (!data.air_way_bill || data.air_way_bill.length < 1) {
        ctx.addIssue({
          path: ['air_way_bill'],
          code: 'custom',
          message: REQUIRED_FIELD,
        });
      }
    }
  });

export type FormSchemaType = TypeOf<ReturnType<typeof dispatchFormSchema>>;
