import { Grid2 } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useIsFirstMount } from '../../../hooks';
import { useSelectOrFetchPuAddress } from '../../../hooks/useSelectOrFetchPuAddress';
import { selectUser } from '../../../services/store/userSlice';
import { $primaryWhite } from '../../../styles/colors';
import { RentalType, businessNumberListRegex, setIdListRegex } from '../../../utils';
import { toLabelValues } from '../../../utils/functions';
import { FormDatePicker, FormTextField } from '../../shared';
import { FormMultiSelect } from '../../shared/form-components/FormMultiSelect';
import { mapRentalTypeValues } from '../utils';

export function ReturnBookingFilter() {
  const { t } = useTranslation('gauge');
  const user = useAppSelector(selectUser);
  const firstMount = useIsFirstMount();
  const { puAddressesLabelValue } = useSelectOrFetchPuAddress();
  const { control } = useFormContext();

  const { replace } = useFieldArray({
    control: control,
    name: 'gauge_location_id',
  });

  const rentalTypeOptions = toLabelValues(RentalType).map((option) => {
    if (option.value === 'short') {
      return { ...option, label: 'STR' };
    } else if (option.value === 'long') {
      return { ...option, label: 'LTR' };
    }
    return option;
  });

  React.useEffect(() => {
    if (firstMount && user.depotId) {
      replace([user.depotId]);
    }
  }, [control._formValues]);

  return (
    <Grid2
      sx={{
        '&.MuiGrid-root': {
          backgroundColor: 'inherit',
        },
        '.MuiTextField-root .MuiOutlinedInput-root': {
          height: 39,
          backgroundColor: $primaryWhite,
        },
      }}
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <Grid2 size={{ xs: 3 }}>
        <FormTextField
          id="business-number-text-fields"
          withFixedLabel
          inputProps={{
            'data-testid': 'list-business-number-input',
          }}
          labelValue={t('details.businessNumber')}
          name="business_number"
          fullWidth
          patternValue={businessNumberListRegex}
          patternMessage="Error syntax"
          placeholder={t('details.businessNumberPlaceholder')}
        />
      </Grid2>

      <Grid2 size={{ xs: 3 }}>
        <FormMultiSelect
          id="rental-type-select"
          name="rental_type"
          label={t('details.rentalType')}
          options={rentalTypeOptions}
          nsTranslate="gauge"
          renderValue={(selected) => mapRentalTypeValues(selected as RentalType[])}
        />
      </Grid2>

      <Grid2 size={{ xs: 3 }}>
        <FormDatePicker
          id="period-end-select"
          inputLabel="Period end"
          variant="outlined"
          name="period_end"
          dataTestId="period_end-test-id"
        />
      </Grid2>

      <Grid2 size={{ xs: 3 }}>
        <FormMultiSelect
          id="depot-select"
          name="gauge_location_id"
          label={t('details.depot')}
          options={puAddressesLabelValue}
          defaultSelectedOptions={[user.depotId]}
        />
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <FormTextField
          id="setids-list-text-fields"
          withFixedLabel
          inputProps={{
            'data-testid': 'list-set-ids-input',
          }}
          labelValue={t('setsManagement.setIds')}
          name="set_id"
          fullWidth
          patternValue={setIdListRegex}
          patternMessage="Error syntax"
          placeholder={t('setsManagement.setIdsPlaceholder')}
        />
      </Grid2>
    </Grid2>
  );
}
