import { ProQualification } from '../../interfaces/context';
import { Applicability } from '../../interfaces/set-model';
import { Products } from '../../interfaces/veg';
import { OD_MAPPING } from '../constants';

export interface DesignationProduct {
  connection?: string;
  od_inch?: number;
  od_txt?: string;
  end?: string;
  min_weight?: number;
  max_weight?: number;
  min_weight_max_weight?: string;
  weight_thickness?: string;
  application?: string;
  option?: string;
}

export function getDesignationProduct({
  connection,
  od_inch,
  od_txt,
  min_weight,
  max_weight,
  min_weight_max_weight,
  weight_thickness,
  application,
  option,
  end,
}: DesignationProduct): string {
  const od = od_txt || OD_MAPPING[od_inch] || od_inch || '';
  const applicationValue = application === 'REGULAR' ? '' : application;
  const optionValue = option === 'REGULAR' ? '' : option;
  const displayToLabel = min_weight && max_weight ? 'to' : '';
  const weightText = min_weight_max_weight
    ? min_weight_max_weight
    : `${min_weight || ''} ${displayToLabel} ${max_weight || ''}`.trim();

  // Use weight_thickness if weightText is empty or null, otherwise use weightText
  const weightThicknessText = weightText ? weightText : weight_thickness;

  return `${connection || ''} ${od || ''} ${weightThicknessText || ''} ${applicationValue || ''} ${
    optionValue || ''
  } ${end || ''}`.trim();
}

export function getDesignationProductWithSetId(
  setId: number,
  designation: DesignationProduct
): string {
  return `#${setId} ${getDesignationProduct(designation)}`;
}

export function removeSpecChars(connection: string): string {
  return connection && connection.replace(/\s|-|@/g, '').toUpperCase();
}

export function getApplicabilitiesFields(connections: Products[]): Applicability[] {
  return connections.map(
    ({ connection, od_inch, weight, end, application, option, thickness }) => ({
      connection,
      od_inch,
      weight,
      end,
      application,
      option,
      thickness,
    })
  );
}

export function getODTxt(od_inch: number): string {
  return OD_MAPPING[od_inch] || `${od_inch}`;
}

export function getProductsInJson(data: Record<string, any>): string {
  const { connection, od_inch, weight_thickness, end, application, option } = data;
  const od_txt = Object.entries(OD_MAPPING).find((val) => {
    return val[0] === od_inch?.toString();
  })?.[1];

  return JSON.stringify({
    connection,
    od_inch,
    od_txt,
    weight_thickness,
    end,
    application,
    option,
  });
}

export function productToQualification(
  connection: string,
  end: string,
  products: ProQualification[]
): boolean {
  const productList = products.filter(
    (product) => product.connection === connection && product.end.includes(end)
  );
  let toBeQualified = false;

  if (productList.length) {
    toBeQualified = productList.some((product) => product.temporary === true);
  }
  return toBeQualified;
}

export function connectionIsTemporary(
  products: ProQualification[],
  connection: string,
  end: string
): boolean {
  const product = products.filter(
    (product) => product.connection === connection && product.end.includes(end)
  );

  return product.length ? product[0].temporary : false;
}
