import { zodResolver } from '@hookform/resolvers/zod';
import { Check } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks';
import { VegCase } from '../../interfaces/case';
import { createNewCase } from '../../services/api/case-api';
import { selectUser } from '../../services/store/userSlice';
import { CaseStatus } from '../../utils/constants/set-model';
import { ContainerOutlet } from '../layout/ContainerOutlet/ContainerOutlet';
import { CtaLoadingButton, VamPageTitle } from '../shared';
import { FormSchemaType, creationFormSchema, defaultValues } from './CaseForm';
import { CaseInformations } from './CaseInformations';

export default function CaseCreation() {
  const { firstName, lastName } = useAppSelector(selectUser);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('gauge_case');

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(creationFormSchema),
    mode: 'onChange',
  });
  const { handleSubmit, reset, setValue } = methods;

  /* istanbul ignore next */
  const addNewCase: SubmitHandler<FormSchemaType> = (data) => {
    const dataApi: VegCase = {
      designation: data.designation,
      width: data.width,
      length: data.length,
      height: data.height,
      weight: data.weight,
      price: data.price,
      case_status: data.case_status as CaseStatus,
      created_by: `${firstName} ${lastName}`,
    };
    createNewCase(dataApi)
      .then(async () => {
        reset();
        ['width', 'length', 'height', 'weight', 'price'].forEach((key: any) => {
          setValue(key, '');
        });
        enqueueSnackbar(t('createCase.saveCaseSuccess'), {
          preventDuplicate: true,
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(t('createCase.saveCaseError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('createCase.tag')}
              title={t('createCase.title')}
              breadcrumbRoutes={[
                {
                  breadcrumb: t('createCase.breadcrumb.caseListing'),
                  path: '../case-listing',
                },
                {
                  breadcrumb: t('createCase.breadcrumb.addNewCase'),
                  path: '#',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <CtaLoadingButton
              buttonText={t('createCase.saveButton')}
              type="submit"
              variant="cta"
              data-testid="submit-create-case-btn"
              startIcon={<Check />}
              onClick={handleSubmit(addNewCase)}
              disabled={
                methods.formState.isLoading ||
                !methods.formState.isValid ||
                !!Object.keys(methods.formState.errors).length
              }
            />
          </Stack>
        </Stack>
        <CaseInformations />
      </FormProvider>
    </ContainerOutlet>
  );
}
