import { InformationBox, SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { FileDownload } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentIcon from '../../assets/icons/icon-set-model.svg';
import { SetGaugeModel, SetGaugeModelUI } from '../../interfaces/set-creation';
import { checkSerialNumberExists } from '../../services/api/set-creation-api';
import { downloadPackingList, exportCalibrationCertificate } from '../../services/api/veg-api';
import { $darkGreen } from '../../styles/colors';
import { parseToValidDate } from '../../utils/functions';
import { VamDataGrid } from '../data-grid/VamDataGrid';
import { GridStatus } from '../data-grid/utils';
import { ContainerCard, VamInformationBox } from '../shared';
import { GaugeError, hasRequiredGaugeError, isGaugeModified, setContentColumns } from './utils';

interface SetContentProps {
  gaugeModels: SetGaugeModelUI[];
  nextCalibrationDate: string;
  updateGaugesGrid: Function;
  with_damage_column?: boolean;
  currentSetId?: number;
}

const SetContent = ({
  gaugeModels,
  nextCalibrationDate,
  updateGaugesGrid,
  with_damage_column,
  currentSetId,
}: SetContentProps) => {
  const { t } = useTranslation('gauge', { keyPrefix: 'setCreation.setContent' });
  const { t: tCommon } = useTranslation('common');
  const [loading, setIsLoading] = React.useState(false);

  // `apiRef` is used to interact directly with the DataGrid API.
  // Since we don't have the Pro version of MUI DataGrid, cell editing
  // normally requires a double-click to activate.
  // With `apiRef`, we can force immediate editing on a single click
  // and check if a cell is already in edit mode to avoid errors.
  const apiRef = useGridApiRef();

  /* istanbul ignore next */
  const fetchGauge = async (row: SetGaugeModel) => {
    checkSerialNumberExists({
      serial_number: row.serial_number,
      reference_number: row.reference_number,
      drawing_number: row.drawing_number,
    })
      .then(({ data }) => {
        const gaugeIsModified = isGaugeModified(gaugeModels, row);
        // here we fetch gauge that matched in db, when data is not null
        // and gaugeIsModified the latest one then show error gauge exists in bdd
        updateGaugesGrid({ ...row, error: data && !gaugeIsModified ? GaugeError.EXISTS : null });
      })
      .catch((error) => {
        console.error('🚀 ~ file: SetContent.tsx ~ fetch ~ checkSerialNumberExists ~ error', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update = (newRow: SetGaugeModel) => {
    updateGaugesGrid(newRow);
    setIsLoading(false);
  };

  async function handleRowEditCommit(newRow: SetGaugeModel): Promise<SetGaugeModel> {
    setIsLoading(true);
    newRow = { ...newRow, serial_number: newRow.serial_number?.trim() };
    newRow.serial_number ? await fetchGauge(newRow) : update(newRow);
    return newRow;
  }

  const downloadCalibrationCertificate = () => {
    exportCalibrationCertificate(currentSetId)
      .then(({ data }) => {
        saveAs(data, `calibration_certificate_document_set_n_${currentSetId}.pdf`);
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  const downloadPackingListDoc = () => {
    downloadPackingList(currentSetId)
      .then(({ data }) => {
        saveAs(data, `packing_list_set_n_${currentSetId}.pdf`);
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerCard
      title={t('title')}
      subTitle={t('subtitle')}
      icon={ContentIcon}
      width="100%"
      maxWidth="100%"
      dataTestId="setContent-container"
      actionHeaderNode={
        <div className="tw-mr-4">
          <VamInformationBox
            title={t('nextCalibration')}
            value={nextCalibrationDate}
            infoIconTitle={t('infoIconTitle')}
          />
        </div>
      }
    >
      <VamDataGrid
        apiRef={apiRef}
        data-testid="grid-set-content"
        hideFooter
        disableRowSelectionOnClick
        disableColumnFilter
        columns={setContentColumns(with_damage_column, handleRowEditCommit).map((column) => ({
          ...column,
          cellClassName: (params) =>
            params.field === 'serial_number' && params.row.grid_status === GridStatus.KO
              ? 'status-ko'
              : '',
        }))}
        rows={gaugeModels.map((gauge: SetGaugeModel) => ({
          ...gauge,
          next_calibration_date: gauge.next_calibration_date
            ? parseToValidDate(gauge.next_calibration_date)
            : null,
          grid_status: hasRequiredGaugeError(gauge) ? GridStatus.KO : GridStatus.OK,
        }))}
        showRowLeftThickBorder
        height={400}
        sx={{
          '.MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            padding: '0 10px',
          },
        }}
        processRowUpdate={handleRowEditCommit}
        onProcessRowUpdateError={(error) => console.error(error)}
        loading={loading} // loading in order to avoid multiple change errors
        onCellClick={
          /*istanbul ignore next */ (params) => {
            if (['serial_number', 'next_calibration_date'].includes(params.field)) {
              const cellState = apiRef.current.getCellMode(params.id, params.field);
              // Check if the cell is already in edit mode before trying to edit it.
              // This prevents the "cell is not in view mode" error.
              if (cellState !== 'edit') {
                // Force the cell into edit mode immediately (avoids the need for a double-click).
                apiRef.current.startCellEditMode({ id: params.id, field: params.field });
              }
            }
          }
        }
        onCellEditStop={
          /*istanbul ignore next */ (params) => {
            if (['serial_number', 'next_calibration_date'].includes(params.field)) {
              // Properly exit edit mode when the user finishes editing the cell.
              apiRef.current.stopCellEditMode({ id: params.id, field: params.field });
            }
          }
        }
      />
      {currentSetId && (
        <>
          <Divider className="!tw-mb-8" />

          <InformationBox
            color={$darkGreen}
            data={[
              {
                title: t('certificateCalibration'),
                name: t('certificateCalibrationDescription'),
              },
            ]}
            justifyContentChildren="space-between"
          >
            <SimpleButton
              size="small"
              variant="icon-secondary-dark-blue"
              data-testid="export-calibration-certificate-btn"
              onClick={downloadCalibrationCertificate}
            >
              <FileDownload />
            </SimpleButton>
          </InformationBox>
          <br />
          <InformationBox
            color={$darkGreen}
            data={[
              {
                title: t('documentPacking'),
                name: t('documentPackingDescription'),
              },
            ]}
            justifyContentChildren="space-between"
          >
            <SimpleButton
              size="small"
              variant="icon-secondary-dark-blue"
              data-testid="export-document-packing-list-btn"
              onClick={downloadPackingListDoc}
            >
              <FileDownload />
            </SimpleButton>
          </InformationBox>
        </>
      )}
    </ContainerCard>
  );
};

export default SetContent;
