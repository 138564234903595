import { SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { isBefore } from 'date-fns';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReturnBookingFilter } from '../../components/booking/return-bookings/ReturnBookingFilter';
import {
  FormSchemaType,
  defaultValues,
  formSchema,
} from '../../components/booking/return-bookings/ReturnBookingFilterForm';
import {
  DataGridStyledGrid,
  GridStatus,
  UNSELECTED_ROW_CLASSNAME,
} from '../../components/data-grid/utils';
import { ContainerOutlet } from '../../components/layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, VamFilter, VamPageTitle } from '../../components/shared';
import { useAppSelector } from '../../hooks';
import { useSelectOrFetchPuAddress } from '../../hooks/useSelectOrFetchPuAddress';
import {
  BookingDistinctFiltersParams,
  BookingsByFiltersParams,
  ManageBookingGrid,
} from '../../interfaces/veg';
import { getBookingsReturn } from '../../services/api/veg-api';
import { selectLicenseeName, selectLicenseeNumber } from '../../services/store/userSlice';
import { $grey20 } from '../../styles/colors';
import {
  BOOKING_STATUS_COLORS,
  BookingStatus,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION_MODEL,
} from '../../utils';
import {
  formatDate,
  getTownById,
  mapToProductFilter,
  mapToProductFilterFromArrays,
  parseToValidDate,
  removeFalsyProperty,
  removeFalsyPropertyFromArrays,
} from '../../utils/functions';
import { redirectTo } from '../../utils/functions';
import ShipIcon from './../../assets/icons/prepare-shipment.svg';
import { RETURN_BOOKINGS_SORT, returnBookingsColumns } from './utils';

export function ReturnBookings() {
  const { t } = useTranslation('gauge');
  const licenseeNumber = useAppSelector(selectLicenseeNumber);
  const { t: tReturn } = useTranslation('shipment', { keyPrefix: 'returnBookings' });
  const { pickupAddress } = useSelectOrFetchPuAddress();
  const [bookings, setBookings] = React.useState<ManageBookingGrid[]>([]);
  const [totalRows, setTotalRows] = React.useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [sortModel, setSortModel] = React.useState<GridSortModel>(RETURN_BOOKINGS_SORT);

  const licenseeName = useAppSelector(selectLicenseeName);
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [currentSelectedBookings, setCurrentSelectedBookings] = React.useState<ManageBookingGrid[]>(
    []
  );
  const [currentSelectedLocation, setCurrentSelectedLocation] = React.useState<number>(null);
  const [dataFilter, setDataFilter] = React.useState<BookingDistinctFiltersParams>({});
  const [loadingGrid, setLoadingGrid] = React.useState<boolean>(false);

  React.useEffect(() => {
    const offset = paginationModel.page * paginationModel.pageSize;

    fetchGetBookings({
      data_filter: {
        ...dataFilter,
        status: [BookingStatus.Delivered],
      },
      pagination: { skip: offset, limit: paginationModel.pageSize },
      sort: sortModel,
    });
  }, [paginationModel.page, sortModel, dataFilter]);

  const fetchGetBookings = async (params: BookingsByFiltersParams) => {
    setLoadingGrid(true);
    getBookingsReturn(licenseeNumber, params)
      .then(({ data }) => {
        const bookings = data?.bookings?.map((row: ManageBookingGrid, idx: number) => ({
          id: idx,
          end_date: formatDate(row.end_date, false),
          ...row,
        }));
        setTotalRows(data?.count);
        setBookings(bookings);
      })
      .catch((error) => {
        console.error('🚀 ~ file: ReturnBooking.tsx ~ fetch ~ error', error);
      })
      .finally(() => {
        setLoadingGrid(false);
      });
  };

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit } = methods;

  /* istanbul ignore next */
  const redirectToMakeReturn = async () => {
    redirectTo(`./make-return`, navigate, {
      state: { from: location, currentSelectedBookings },
    });
  };

  const applyFilter: SubmitHandler<FormSchemaType> = async (data) => {
    const transformedData = {
      ...data,
    };

    const filter = {
      ...removeFalsyPropertyFromArrays(
        mapToProductFilterFromArrays(transformedData, [
          'rental_type',
          'gauge_location_id',
          'business_number',
          'set_id',
        ]) as Record<string, any[]>
      ),
      ...removeFalsyProperty(mapToProductFilter({ period_end: data.period_end }, ['period_end'])),
    };
    setDataFilter(filter);

    const skipPage = paginationModel.page * paginationModel.pageSize;
    await fetchGetBookings({
      data_filter: { ...filter, status: [BookingStatus.Delivered] },
      pagination: { skip: skipPage, limit: paginationModel.pageSize },
      sort: sortModel,
    });
  };

  return (
    <ContainerOutlet>
      <VamPageTitle
        subTitle={t('tag')}
        title={tReturn('title')}
        breadcrumbRoutes={[
          {
            breadcrumb: 'Return Bookings',
            path: '#',
          },
        ]}
      />
      <FormProvider {...methods}>
        <VamFilter
          title={`${tReturn('licensee')}: #${licenseeNumber} - ${licenseeName}`}
          subTitle={`${tReturn('filterSubtitle')}`}
          handleApplyFilter={handleSubmit(applyFilter)}
          disableApplyBtn={loadingGrid}
          defaultValues={{
            ...defaultValues,
          }}
        >
          <ReturnBookingFilter />
        </VamFilter>
      </FormProvider>

      <ContainerCard
        width="100%"
        maxWidth="100%"
        containerCardClass="tw-pt-3"
        dataTestId="return-bookings-container-card"
        avatarHeaderNode={<></>}
        actionHeaderNode={
          <SimpleButton
            variant="cta"
            size="small"
            endIcon={<img src={ShipIcon} alt="shipment-icon" />}
            onClick={redirectToMakeReturn}
            data-testid="make-return-proceed-btn"
            disabled={currentSelectedBookings?.length === 0}
          >
            {tReturn('proceedToReturn')}
          </SimpleButton>
        }
      >
        <DataGridStyledGrid
          initialStateColumns={{
            columnVisibilityModel: {
              licensee_name: false,
              connection: false,
            },
          }}
          columns={returnBookingsColumns
            .map((column) => ({
              ...column,
              cellClassName: /* istanbul ignore next */ (params) =>
                currentSelectedLocation && params.row.set_location_id !== currentSelectedLocation
                  ? UNSELECTED_ROW_CLASSNAME
                  : '',
            }))
            .concat([
              {
                field: 'status',
                headerName: 'STATUS',
                cellClassName: /* istanbul ignore next */ () => '',
                renderCell: /* istanbul ignore next */ (
                  params: GridRenderCellParams<any, BookingStatus>
                ) => (
                  <Typo
                    variant="body2"
                    color={
                      currentSelectedLocation &&
                      currentSelectedLocation !== params.row.set_location_id
                        ? $grey20
                        : BOOKING_STATUS_COLORS[params.value]
                    }
                    fontWeight={500}
                    className="text-ellipsis tw-inline-flex"
                  >
                    {t(`manageBookingDetail.changeStatusDialog.status.${params.value}`)
                      .toString()
                      .toUpperCase()}
                  </Typo>
                ),
                minWidth: 120,
                flex: 1,
                sortable: false,
                disableColumnMenu: true,
              },
            ])}
          rows={bookings.map((booking) => {
            const bookingEndDate = parseToValidDate(booking.end_date);

            return {
              ...booking,
              gauge_location: getTownById(pickupAddress, booking.set_location_id),
              grid_status: isBefore(bookingEndDate, new Date())
                ? GridStatus.EXPIRE
                : GridStatus.STANDARD,
            };
          })}
          disableColumnFilter
          showRowLeftThickBorder
          checkboxSelection
          onRowSelectionModelChange={
            /* istanbul ignore next */ (rowSelectionModel: GridRowSelectionModel) => {
              setCurrentSelectedLocation(
                bookings?.[rowSelectionModel[0] as number]?.set_location_id
              );
              setCurrentSelectedBookings(
                bookings.filter((booking) => rowSelectionModel.includes(booking.id))
              );
              setSelectionModel(rowSelectionModel);
            }
          }
          rowSelectionModel={selectionModel}
          keepNonExistentRowsSelected
          autoHeight
          isRowSelectable={(params) =>
            !currentSelectedLocation || params.row.set_location_id === currentSelectedLocation
          }
          rowCount={totalRows}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSize={DEFAULT_PAGE_SIZE}
          paginationMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
        />
      </ContainerCard>
    </ContainerOutlet>
  );
}
