import { TypeOf, boolean, object, string } from 'zod';

import { INVALID_EMAIL, INVALID_NUMERIC, REQUIRED_FIELD, numberRegex } from '../../utils';

export const creationFormSchema = object({
  site: string().min(1, REQUIRED_FIELD),
  street: string().min(1, REQUIRED_FIELD),
  town: string().min(1, REQUIRED_FIELD),
  country_code: string().min(1, REQUIRED_FIELD),
  email: string().email({ message: INVALID_EMAIL }),
  zip_code: string().min(1, REQUIRED_FIELD),
  phone_number: string()
    .min(1, REQUIRED_FIELD)
    .refine((value) => numberRegex.test(value), {
      message: INVALID_NUMERIC,
    }),
  is_active: boolean(),
  licensee_number: string().optional().nullable(),
});

export const defaultValues: FormSchemaType = {
  site: '',
  street: '',
  town: '',
  country_code: '',
  email: '',
  zip_code: '',
  phone_number: '',
  is_active: true,
  licensee_number: '',
};

// infer schema to generate typescript type
export type FormSchemaType = TypeOf<typeof creationFormSchema>;
