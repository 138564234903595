import { InformationBox, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { InputAdornment } from '@mui/material';
import { FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { $primaryBlack } from '../../styles/colors';
import { FormTextField } from '../shared';
import { SUBTOTAL_KEY, SUBTOTAL_VALUE_KEY } from './utils';

interface SubtotalInvoiceProps {
  subTotalPrice: number;
  color?: string;
  formNameControl?: string;
  transportAndSetDisable?: boolean;
  handleBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export function SubTotalInvoice({
  subTotalPrice,
  color,
  formNameControl,
  transportAndSetDisable,
  handleBlur,
}: SubtotalInvoiceProps) {
  const { t } = useTranslation('invoice');

  return (
    <div className="tw-w-full">
      <InformationBox color={color || $primaryBlack}>
        <div className="tw-flex tw-w-full tw-justify-between tw-h-6 tw-items-center">
          <Typo
            className="tw-whitespace-nowrap"
            variant="labelForStatus"
            fontWeight={500}
            fontStyle="italic"
            color={$primaryBlack}
          >
            {t(SUBTOTAL_KEY)}
          </Typo>
          {formNameControl ? (
            <div className="xs:tw-w-1/3 lg:tw-w-1/4">
              <FormTextField
                id={formNameControl}
                name={formNameControl}
                variant="standard"
                size="small"
                type="number"
                InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                onBlur={handleBlur}
                inputDataTestId={`${formNameControl}-textfield`}
                disabled={transportAndSetDisable}
              />
            </div>
          ) : (
            <Typo
              className="tw-whitespace-nowrap"
              variant="labelForStatus"
              fontWeight={500}
              fontStyle="normal"
              color={$primaryBlack}
            >
              {t(SUBTOTAL_VALUE_KEY, { value: subTotalPrice })}
            </Typo>
          )}
        </div>
      </InformationBox>
    </div>
  );
}
