import { Menu, MenuItem } from '@mui/material';

export interface MenuOptionsProps {
  label: string;
  name?: string;
  disabled?: boolean;
  handleItemClick?: Function;
  selectedIndex?: number;
  color?: string;
}

export interface VamMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: Function;
  options: MenuOptionsProps[];
  row?: unknown;
}

/* istanbul ignore next */
export function VamMenu({
  anchorEl,
  open,
  handleClose,
  options,
  row,
  ...otherProps
}: VamMenuProps) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleClose()}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        role: 'listbox',
      }}
      {...otherProps}
    >
      {options.map((option, index) => (
        <MenuItem
          key={option.label}
          disabled={option.disabled}
          selected={index === option.selectedIndex}
          data-testid={`datatestid + ${option.label}`}
          onClick={(event) => {
            option.handleItemClick(event, index, row);
            handleClose();
          }}
          sx={{ color: option.color }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
}
