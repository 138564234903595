import {
  SectionTitle,
  SimpleButton,
  Tabs,
  Tooltip,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import {
  $darkGreen,
  $secondaryBlue,
} from '@digital-at-vallourec/steel-design-system-react/dist/styles/colors';
import { DateRange } from '@mui/icons-material';
import { GridPaginationModel, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useAppSelector } from '../../../hooks';
import { useFetchGaugesBookingByStatus } from '../../../hooks/useFetchGaugesBookingByStatus';
import { useSelectOrFetchStatusCount } from '../../../hooks/useSelectOrFetchGaugesBookingCount';
import { ManageBookingGrid } from '../../../interfaces/veg';
import { bulkExtendReservationLtr } from '../../../services/api/veg-api';
import {
  selectDepotId,
  selectLicenseeNumber,
  selectUserId,
} from '../../../services/store/userSlice';
import {
  BOOKING_STATUS_COLORS,
  BookingStatus,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION_MODEL,
} from '../../../utils';
import { redirectTo } from '../../../utils/functions/helpers';
import { VamDataGrid } from '../../data-grid/VamDataGrid';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard } from '../../shared';
import { manageBookingColumns } from '../utils';
import { ManageBookingCommon, QuickAccessButtonProps } from './ManageBookingCommon';

export function ManageBooking() {
  const { t } = useTranslation('gauge');
  const userId = useAppSelector(selectUserId);
  const licenseeNumber = useAppSelector(selectLicenseeNumber);
  const [status, setStatus] = useState<BookingStatus>(undefined);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);
  const [sort, setSort] = React.useState<GridSortModel>([]);
  const depotId = useAppSelector(selectDepotId);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const location = useLocation();
  const navigate = useNavigate();
  const allStatuses = [
    BookingStatus.NewOrder,
    BookingStatus.ManufacturingGauge,
    BookingStatus.WaitForInformation,
    BookingStatus.NotAvailable,
    BookingStatus.AvailableInOtherSite,
    BookingStatus.ToBeExtended,
    BookingStatus.ReplacementRequested,
    BookingStatus.ReplacementDone,
  ];

  const { statusCount, refetch: refetchCounters } = useSelectOrFetchStatusCount(
    allStatuses,
    licenseeNumber
  );
  const { datagrid: bookings, refetch: refetchBookings } = useFetchGaugesBookingByStatus(
    { all_statuses: allStatuses, current_status: status },
    sort,
    licenseeNumber,
    paginationModel.page,
    null,
    depotId
  );

  /* istanbul ignore next */
  const tabs = [
    {
      label: t('manage_booking.tabs.all_reservations', { context: 'manageBooking' }),
      onClick: () => handleTabChange(undefined),
      counter: statusCount['all_reservations'],
    },
    {
      label: t(`manage_booking.tabs.${BookingStatus.NewOrder}`),
      onClick: () => handleTabChange(BookingStatus.NewOrder),
      counter: statusCount[BookingStatus.NewOrder],
    },
    {
      label: t(`manage_booking.tabs.${BookingStatus.WaitForInformation}`),
      onClick: () => handleTabChange(BookingStatus.WaitForInformation),
      counter: statusCount[BookingStatus.WaitForInformation],
    },
    {
      label: t(`manage_booking.tabs.${BookingStatus.ManufacturingGauge}`),
      onClick: () => handleTabChange(BookingStatus.ManufacturingGauge),
      counter: statusCount[BookingStatus.ManufacturingGauge],
    },
    {
      label: t(`manage_booking.tabs.${BookingStatus.AvailableInOtherSite}`),
      onClick: () => handleTabChange(BookingStatus.AvailableInOtherSite),
      counter: statusCount[BookingStatus.AvailableInOtherSite],
    },
    {
      label: t(`manage_booking.tabs.${BookingStatus.NotAvailable}`),
      onClick: () => handleTabChange(BookingStatus.NotAvailable),
      counter: statusCount[BookingStatus.NotAvailable],
    },
    {
      label: t(`manage_booking.tabs.${BookingStatus.ToBeExtended}`),
      onClick: () => handleTabChange(BookingStatus.ToBeExtended),
      counter: statusCount[BookingStatus.ToBeExtended],
    },
    {
      label: t(`manage_booking.tabs.${BookingStatus.ReplacementRequested}`),
      onClick: () => handleTabChange(BookingStatus.ReplacementRequested),
      counter: statusCount[BookingStatus.ReplacementRequested],
    },
    {
      label: t(`manage_booking.tabs.${BookingStatus.ReplacementDone}`),
      onClick: () => handleTabChange(BookingStatus.ReplacementDone),
      counter: statusCount[BookingStatus.ReplacementDone],
    },
  ];

  /*istanbul ignore next */
  async function redirectToBookingDetail(row: ManageBookingGrid) {
    const { booking_id, business_number } = row;
    redirectTo(`../booking/${booking_id}`, navigate, {
      state: { from: location, business_number },
    });
  }

  /* istanbul ignore next */
  const handleTabChange = (status: BookingStatus) => {
    setPaginationModel({ ...paginationModel, page: 0 });
    setStatus(status);
  };

  /* istanbul ignore next */
  const accessButtons: QuickAccessButtonProps[] = [
    {
      title: t('manage_booking.quickAccessButtons.search.title'),
      subtitle: t('manage_booking.quickAccessButtons.search.subtitle'),
      dataTestId: 'vam-global-search-quick-access-button',
      handleClick: () => redirectTo('../rental-data', navigate, { state: { from: location } }),
    },
    {
      title: t('manage_booking.quickAccessButtons.booking.title', { context: 'manageBooking' }),
      subtitle: t('manage_booking.quickAccessButtons.booking.subtitle', {
        context: 'manageBooking',
      }),
      dataTestId: 'vam-manageBooking-quick-access-button',
      handleClick: () => redirectTo('../current-rentals', navigate, { state: { from: location } }),
    },
  ];

  /* istanbul ignore next */
  const handleRowSelection = (rowId: number) => {
    setSelectedRowIds((prevSelected) => {
      if (prevSelected.includes(rowId)) {
        return prevSelected.filter((id) => id !== rowId); // Unselect
      } else {
        return [...prevSelected, rowId]; // Select
      }
    });
  };

  /* istanbul ignore next */
  const handleBulkExtendClick = () => {
    const bookingsForRenewal = bookings.filter((row) => selectedRowIds.includes(row.booking_id));
    Swal.fire({
      icon: 'warning',
      title: t('manageBookingDetail.extendDialog.title', { count: bookingsForRenewal.length }),
      html: t('manageBookingDetail.extendDialog.text', {
        count: bookingsForRenewal.length,
        businessNumber: bookingsForRenewal.map((b) => b.business_number).join(', '),
      }),
      showCancelButton: true,
      confirmButtonText: t('manageBookingDetail.extendDialog.confirm'),
      confirmButtonColor: $darkGreen,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await bulkExtendReservationLtr(
          bookingsForRenewal.map((booking) => ({
            booking_id: booking.booking_id,
            user_id: userId,
            extended_purchase_order: booking.purchase_order,
          }))
        );
        setPaginationModel({ ...paginationModel, page: 0 });
        setSelectedRowIds([]);
        refetchBookings();
        refetchCounters();
      }
    });
  };

  return (
    <ContainerOutlet>
      <ManageBookingCommon
        title={t('manage_booking.title', { context: 'manageBooking' })}
        subtitle={t('tag')}
        breadcrumbRoutes={[
          {
            breadcrumb: t('manage_booking.title', { context: 'manageBooking' }),
            path: '#',
          },
        ]}
        accessButtons={accessButtons}
      />
      <Tabs
        color="secondary"
        className="tw-ml-4"
        tabs={tabs}
        sx={{
          '.MuiBadge-badge': {
            backgroundColor: $secondaryBlue,
          },
        }}
      />
      <ContainerCard
        width="100%"
        maxWidth="100%"
        containerCardClass="tw-pt-3"
        dataTestId="booking-container"
        avatarHeaderNode={
          <SectionTitle
            title={t(`manage_booking.card_${status || 'all_reservation'}.title`, {
              context: 'manageBooking',
            })}
            description={t(`manage_booking.card_${status || 'all_reservation'}.subtitle`, {
              context: 'manageBooking',
            })}
          />
        }
        actionHeaderNode={
          status === BookingStatus.ToBeExtended && (
            <SimpleButton
              disabled={!selectedRowIds.length}
              variant="cta-sky-blue"
              startIcon={<DateRange />}
              onClick={handleBulkExtendClick}
            >
              {t('manageBookingDetail.extendSixMonths')}
            </SimpleButton>
          )
        }
      >
        <VamDataGrid
          getRowId={(row) => row.booking_id}
          initialStateColumns={{
            columnVisibilityModel: {
              modification_date: false,
              status: false,
              application: false,
              booking_id: false,
              select: status === BookingStatus.ToBeExtended,
            },
          }}
          columns={manageBookingColumns(selectedRowIds, handleRowSelection).concat([
            {
              field: 'comments',
              headerName: 'COMMENTS',
              renderCell: (params) => (
                <Tooltip arrow placement="left" title={params.value}>
                  <div className="text-ellipsis tw-inline-flex">
                    <Typo variant="body2" fontWeight={500} className="text-ellipsis">
                      {params.value}
                    </Typo>
                  </div>
                </Tooltip>
              ),
              minWidth: 120,
              flex: 1,
            },
            {
              field: 'status',
              headerName: 'STATUS',
              renderCell: /* istanbul ignore next */ (
                params: GridRenderCellParams<any, BookingStatus>
              ) => (
                <Typo
                  variant="body2"
                  color={BOOKING_STATUS_COLORS[params.value]}
                  fontWeight={500}
                  className="text-ellipsis tw-inline-flex"
                >
                  {t(`manageBookingDetail.changeStatusDialog.status.${params.value}`)}
                </Typo>
              ),
              minWidth: 120,
              flex: 1,
            },
          ])}
          rows={bookings}
          height={500}
          disableColumnFilter
          onRowDoubleClick={({ row }: GridRowParams) => {
            /* istanbul ignore next */
            redirectToBookingDetail(row);
          }}
          showRowLeftThickBorder
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          rowCount={statusCount[status || 'all_reservations'] || 0}
          pageSize={DEFAULT_PAGE_SIZE}
          paginationMode="server"
          keepNonExistentRowsSelected
          sortingMode="server"
          filterMode="server"
          sortModel={sort}
          onSortModelChange={setSort}
        />
      </ContainerCard>
    </ContainerOutlet>
  );
}
