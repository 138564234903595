import { GridSortModel } from '@mui/x-data-grid';

import { GridStatus } from '../../components/data-grid/utils';

export const LTR_SIX_MONTHS_DAYS = 185;

export enum DeliveryMode {
  Delivery = 'DELIVERY',
  ClicknCollect = 'CLICK_AND_COLLECT',
}

export const MAP_INDEX_DELIVERY = {
  DELIVERY: 0,
  CLICK_AND_COLLECT: 1,
};

export const MAP_DELIVERY_MODE_I18N = {
  DELIVERY: 'delivery',
  CLICK_AND_COLLECT: 'click',
};

export enum RentalType {
  Short = 'short',
  Long = 'long',
}

export const BidentConnection = [
  'VAMEDGE',
  'VAMHTF',
  'VAMHTTC',
  'VAMHTTCNA',
  'VAMHTFNR',
  'VAMHTFNA',
  'VAMBOLT',
  'VAMBOLTII',
  'VAMBOLTNA',
  'VAMSPRINTSF',
  'VAMSPRINTTC',
  'VAMSPRINTFJ',
  'VAMEDGESF',
  'VAMLIFT',
  'VAMFPO',
];

export enum VegWebsiteStatus {
  Active = 'active',
  Waiting = 'waiting',
  Draft = 'draft',
}

export enum VegLocation {
  AulnoyeAymeries = '1',
  Houston = '2',
  Aberdeen = '3',
  Singapore = '4',
  AbuDhabi = '5',
}

export const WEBSITE_STATUS_GRID_MAPPING = {
  ACTIVE: GridStatus.OK,
  WAITING: GridStatus.EXPIRE,
  DRAFT: GridStatus.GREY,
};

export const DISPATCH_SUMMARY_SORT: GridSortModel = [{ field: 'licensee_number', sort: 'asc' }];

export enum DispatchBy {
  DHL = 'dhl',
  FEDEX = 'fedex',
  OTHER = 'other',
  CLICK_AND_COLLECT = 'click_and_collect',
}

// TODO FIXME in US ITC-5669-544
export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  CAD = 'CAD',
  GBP = 'GBP',
  MXN = 'MXN',
  BRL = 'BRL',
  JPY = 'JPY',
  CNY = 'CNY',
}

export enum ShippingSource {
  Dispatch = 'dispatch',
  Return = 'return',
  ReturnConfirmed = 'return_confirmed',
  DELIVERED = 'delivered',
}

export enum CurrencyPlantRoyalty {
  EURO = 'EUR',
  DOLLAR = 'USD',
  CANADIAN_DOLLAR = 'CAD',
  POUND = 'GBP',
  MEXICAN_PESO = 'MXN',
  BRAZILIAN_REAL = 'BRL',
  YEN = 'JPY',
  RENMINBI = 'CNY',
}
