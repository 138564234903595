import {
  InformationBox,
  LabelBox,
  LabelValue,
  PersonalInformations,
  SectionTitle,
  StatusBox,
} from '@digital-at-vallourec/steel-design-system-react';
import { Grid2 } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { InvoiceDetail, InvoiceLicensee } from '../../interfaces/invoice';
import { $primaryNavy, $primaryWhite } from '../../styles/colors';
import { DeliveryMode, MAP_INDEX_DELIVERY } from '../../utils';
import { InvoiceType } from '../../utils/constants/invoice';
import { mappingAddressToUserInformationsAddress } from '../../utils/functions';
import { ContainerCard, FormTextField } from '../shared';
import { StatusLabelBox } from '../shared/StatusLabelBox/StatusLabelBox';

export interface RentalInformationsProps {
  invoice: InvoiceDetail;
  licensee?: InvoiceLicensee;
}

export function RentalInformations({ invoice, licensee }: RentalInformationsProps) {
  const { t: tGauge } = useTranslation('gauge');
  const { t } = useTranslation('invoice');

  const { getValues } = useFormContext();

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={
        <SectionTitle
          title={t('invoiceDetails.rentalInformations.title')}
          description={t('invoiceDetails.rentalInformations.description')}
        />
      }
    >
      <Grid2 container spacing={3}>
        <Grid2 size={6} display="flex" justifyContent="start">
          <LabelValue
            label={t('invoiceDetails.rentalInformations.licensee')}
            value={licensee ? `#${invoice.licensee_number} - ${licensee?.licensee_name}` : '-'}
            isUpperCaseLabel
            labelWeight={500}
            inputWidth={400}
          />
        </Grid2>
        <Grid2 size={6} display="flex" justifyContent="end">
          <FormTextField
            label={t('invoiceDetails.rentalInformations.po')}
            id="po-reference-input"
            name="po_reference"
            variant="outlined"
            inputDataTestId="po-reference-input-data-testid"
          />
        </Grid2>
        <Grid2 size={6} display="flex" justifyContent="start">
          <LabelValue
            label={t('invoiceDetails.rentalInformations.business')}
            value={invoice.business_number || '-'}
            isUpperCaseLabel
            labelWeight={500}
            inputWidth={400}
          />
        </Grid2>
        {invoice?.is_veg_replacement ? (
          <Grid2 size={6} data-testid="set-designation-section">
            <div className="tw-px-4 tw-mt-3">
              <LabelBox
                background={$primaryNavy}
                color={$primaryWhite}
                label={t('invoiceDetails.rentalInformations.isReplacement')}
                width="auto"
                height="24px"
                padding="4px 16px"
                margin="0 8px"
                lineHeight="16px"
              />
            </div>
          </Grid2>
        ) : null}
        {invoice.set_designation ? (
          <Grid2 size={12} data-testid="set-designation-section">
            <InformationBox
              data={[
                {
                  title: t('invoiceDetails.rentalInformations.informationBox'),
                  name: invoice.set_designation,
                },
              ]}
            />
          </Grid2>
        ) : null}
        <Grid2 size={12} data-testid="key-user-section">
          <div className="tw-flex tw-w-full">
            <PersonalInformations
              informations={{
                ...licensee.key_user,
                firstname: licensee.key_user?.first_name,
                lastname: licensee.key_user?.last_name,
                phoneNumber: licensee.key_user?.phone_number,
              }}
            />
          </div>
        </Grid2>
        <Grid2 size={12} data-testid="invoicing-address-section">
          <div className="tw-flex tw-w-full">
            <PersonalInformations
              isAddress
              addressTitle={t('invoiceDetails.rentalInformations.invoicingAddress')}
              informations={mappingAddressToUserInformationsAddress(licensee.invoicing_address)}
            />
          </div>
        </Grid2>
        {invoice.delivery_mode ? (
          <Grid2 size={12} data-testid="delivery-mode-section">
            <div className="tw-flex tw-justify-center tw-gap-4">
              <StatusLabelBox
                label={tGauge('mode.qualification')}
                isActive={invoice.for_qualification}
              />
              <StatusBox
                activeNodeIndex={MAP_INDEX_DELIVERY[invoice.delivery_mode]}
                data={[tGauge('mode.delivery'), tGauge('mode.click')]}
              />
            </div>
          </Grid2>
        ) : null}
        {invoice.invoice_type === InvoiceType.BOOKING &&
        invoice.delivery_mode !== DeliveryMode.ClicknCollect ? (
          <Grid2 size={6} data-testid="awb-number-dispatch-section">
            <FormTextField
              id="awb_number_dispatch"
              name="awb_number_dispatch"
              label={
                <Trans components={{ bold: <strong /> }}>
                  {t('invoiceDetails.rentalInformations.awbDispatch', {
                    dispatch_date: getValues('dispatch_date'),
                  })}
                </Trans>
              }
              inputDataTestId="awb-number-dispatch-input"
              variant="outlined"
            />
          </Grid2>
        ) : null}
        <Grid2 size={6} data-testid="awb-number-dispatch-section">
          <FormTextField
            id="awb_number_return"
            name="awb_number_return"
            label={
              <Trans components={{ bold: <strong /> }}>
                {t('invoiceDetails.rentalInformations.awbReturn', {
                  return_date: getValues('return_date'),
                })}
              </Trans>
            }
            inputDataTestId="awb-number-return-input"
            variant="outlined"
          />
        </Grid2>
        <Grid2 size={12}>
          <FormTextField
            id="comments"
            name="comments"
            label={t('invoiceDetails.rentalInformations.comment')}
            variant="outlined"
            fullWidth
            data-testid="comment-textfield"
            multiline
            rows={4}
          />
        </Grid2>
      </Grid2>
    </ContainerCard>
  );
}
